import React, {Component} from "react";
import {connect} from "react-redux";
import {formatInTimeZone} from "date-fns-tz";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import {withTranslation} from "react-i18next";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";

const COMPANY_ACTION_LOG_TYPES = {
	COMPANY_CREATED: { translateText: (props) => props.t('company.actionLog_COMPANY_CREATED')},
	COMPANY_UPDATED_NAME: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_NAME')},
	COMPANY_UPDATED_CONTACT_EMAIL: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_CONTACT_EMAIL')},
	COMPANY_UPDATED_ITSME_ATTRIBUTE_TYPES: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_ATTRIBUTE_TYPES')},
	COMPANY_UPDATED_ITSME_CONFIG_ADD: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_CONFIG_ADD')},
	COMPANY_UPDATED_ITSME_CONFIG_DELETE: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_CONFIG_DELETE')},
	COMPANY_UPDATED_ITSME_CLIENT_ID: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_CLIENT_ID')},
	COMPANY_UPDATED_ITSME_OAUTH2_SCOPES: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_OAUTH2_SCOPES')},
	COMPANY_UPDATED_ITSME_OIDC_CLAIMS: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_OIDC_CLAIMS')},
	COMPANY_UPDATED_ITSME_OPENID_CONFIGURATION_ENDPOINT: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_OPENID_CONFIGURATION_ENDPOINT')},
	COMPANY_UPDATED_ITSME_SERVICE_CODE: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_ITSME_SERVICE_CODE')},
	COMPANY_UPDATED_MYID_CLIENT_ID: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_MYID_CLIENT_ID')},
	COMPANY_UPDATED_MYID_OAUTH2_SCOPES: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_MYID_OAUTH2_SCOPES')},
	COMPANY_UPDATED_MYID_CLIENT_SECRET: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_MYID_CLIENT_SECRET')},
	COMPANY_UPDATED_SUBDOMAIN: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_SUBDOMAIN')},
	COMPANY_UPDATED_CUSTOMDOMAIN: { translateText: (props) => props.t('company.actionLog_COMPANY_UPDATED_CUSTOMDOMAIN')},
	COMPANY_CREATED_API_KEY: { translateText: (props) => props.t('company.actionLog_COMPANY_CREATED_API_KEY')},
	COMPANY_REVOKED_API_KEY: { translateText: (props) => props.t('company.actionLog_COMPANY_REVOKED_API_KEY')},
	COMPANY_CREATED_IDP_CLIENT_SECRET: { translateText: (props) => props.t('company.actionLog_COMPANY_CREATED_IDP_CLIENT_SECRET')},
	COMPANY_REVOKED_IDP_CLIENT_SECRET: { translateText: (props) => props.t('company.actionLog_COMPANY_REVOKED_IDP_CLIENT_SECRET')},
	COMPANY_IDP_FLOW_CREATED: { translateText: (props) => props.t('company.actionLog_COMPANY_IDP_FLOW_CREATED')},
	COMPANY_IDP_FLOW_DELETED: { translateText: (props) => props.t('company.actionLog_COMPANY_IDP_FLOW_DELETED')},
	COMPANY_IDP_FLOW_DESCRIPTION_UPDATED: { translateText: (props) => props.t('company.actionLog_COMPANY_IDP_FLOW_DESCRIPTION_UPDATED')},
	COMPANY_IDP_FLOW_FOLDER_UPDATED: { translateText: (props) => props.t('company.actionLog_COMPANY_IDP_FLOW_FOLDER_UPDATED')},
	COMPANY_IDP_FLOW_ITSME_CONFIG_UPDATED: { translateText: (props) => props.t('company.actionLog_COMPANY_IDP_FLOW_ITSME_CONFIG_UPDATED')},

	FOLDER_CREATE: { translateText: (props) => props.t('company.actionLog_FOLDER_CREATE')},
	FOLDER_DELETE: { translateText: (props) => props.t('company.actionLog_FOLDER_DELETE')},
	FOLDER_ACCOUNT_PERMISSION_DELETE: { translateText: (props) => props.t('company.actionLog_FOLDER_ACCOUNT_PERMISSION_DELETE')},
	FOLDER_ACCOUNT_PERMISSION_CREATE: { translateText: (props) => props.t('company.actionLog_FOLDER_ACCOUNT_PERMISSION_CREATE')},

	ACCOUNT_INVITED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_INVITED')},
	ACCOUNT_REINVITED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_REINVITED')},
	ACCOUNT_PASSWORD_SET: { translateText: (props) => props.t('company.actionLog_ACCOUNT_PASSWORD_SET')},
	ACCOUNT_INVALID_PASSWORD: { translateText: (props) => props.t('company.actionLog_ACCOUNT_INVALID_PASSWORD')},
	ACCOUNT_AUTHENTICATED_OIDC: { translateText: (props) => props.t('company.actionLog_ACCOUNT_AUTHENTICATED_OIDC')},
	ACCOUNT_AUTHENTICATED_PASSWORD: { translateText: (props) => props.t('company.actionLog_ACCOUNT_AUTHENTICATED_PASSWORD')},
	ACCOUNT_TERMS_AND_STATEMENTS_ACCEPTED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_TERMS_AND_STATEMENTS_ACCEPTED')},
	ACCOUNT_UPDATED_PASSWORD: { translateText: (props) => props.t('company.actionLog_ACCOUNT_UPDATED_PASSWORD')},
	ACCOUNT_RESET_PASSWORD_REQUESTED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_RESET_PASSWORD_REQUESTED')},
	ACCOUNT_REMOVED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_REMOVED')},
	ACCOUNT_DEACTIVATED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_DEACTIVATED')},
	ACCOUNT_REACTIVATED: { translateText: (props) => props.t('company.actionLog_ACCOUNT_REACTIVATED')}
}

class CompanyActionLogsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'performedAt', sort: 'desc'}],
			// columns
			extraColumns: ((() => {
				try {
					const columns = JSON.parse(localStorage.getItem('company-action-log-columns'));
					if (!!columns) {
						return columns;
					}
				} catch (e) {}
				return ['performedAt', 'actorFullName', 'event'];
			})())
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo?.defaultRowCount)
		}, () => this.onCompanyFetchActionLogs());
	}

	render() {
		const allPossibleDataGridColumns = [
			{
				field: 'performedAt',
				headerName: this.props.t('company.actionLogPerformedAt'),
				editable: false,
				optional: false,
				flex: 1,
				valueGetter: (data) => !!data.value ? formatInTimeZone(data.value, 'Europe/Brussels', "dd-LL-yyyy HH:mm:ss") : ''
			},
			{
				field: 'actorFullName',
				headerName: this.props.t('company.actionLogActor'),
				editable: false,
				optional: true,
				flex: 1,
			},
			{
				field: 'type',
				headerName: this.props.t('company.actionLogEvent'),
				editable: false,
				optional: false,
				flex: 3,
				renderCell: (cellValues) => COMPANY_ACTION_LOG_TYPES[cellValues.row.type].translateText(this.props).replace(/\{[0-9]+\}/g, (p) => {
					const index = parseInt(p.substring(1, p.length - 1));
					return cellValues.row.data[index] || '?';
				})
			},
		];

		return <DataGrid
			autoHeight
			disableColumnSelector
			disableColumnFilter
			columns={allPossibleDataGridColumns.filter(column => !column.optional || this.state.extraColumns.indexOf(column.field) >= 0)}
			slots={{
				toolbar: FlexibleToolbar,
			}}
			slotProps={{
				toolbar: {
					filterId: 'input-company-action-logs-search-text',
					filterValue: this.state.filterValue,
					onChangeFilterValue: this.onFilterValueChange,
					possibleColumns: allPossibleDataGridColumns.filter(column => column.optional),
					columns: this.state.extraColumns,
					onChangeColumns: this.onChangeExtraColumns,
				}
			}}

			loading={this.props.busy}

			pagination
			paginationMode="server"
			paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
			onPaginationModelChange={this.onPaginationModelChange}
			pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

			sortingMode="server"
			sortModel={this.state.sortModel}
			onSortModelChange={this.onSortModelChange}

			disableRowSelectionOnClick

			rows={this.props.companyActionLogList}
			rowCount={this.props.companyActionLogCount}
			density="compact"/>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanyFetchActionLogs)
	}

	onChangeExtraColumns = (extraColumns) => {
		localStorage.setItem('company-action-log-columns', JSON.stringify(extraColumns));
		this.setState({extraColumns});
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchActionLogs)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchActionLogs);
	}

	onCompanyFetchActionLogs = () => {
		this.props.onCompanyFetchActionLogs({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		});
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyActionLogList: state.company.actionLogList,
			companyActionLogCount: state.company.actionLogCount,
			busy: state.company.busy
		}
	},
	dispatch => {
		return {
			onCompanyFetchActionLogs: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_ACTION_LOGS',
					request
				})
			}
		}
	}
)(CompanyActionLogsComponent));
