const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// list overview
	overviewList: [],
	overviewCount: 0,

	// crud
	created: null,
	actionSuccessfully: false,
	settings: null,

	// permissions
	relevantAccounts: null,
	permissions: null,

};

const folder = (state = defaultState, action) => {
	switch (action.type) {
		case 'FOLDER_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'FOLDER_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'FOLDER_FETCH_OVERVIEW_LIST':
		case 'FOLDER_FETCH_RELEVANT_ACCOUNTS':
		case 'FOLDER_FETCH_PERMISSIONS':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'FOLDER_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'FOLDER_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				actionSuccessfully: false,
				created: null,
			}

		case 'FOLDER_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				actionSuccessfully: true,
				created: action.data,
			}

		case 'FOLDER_FETCH_SETTINGS':
			return {
				...state,
				busy: true,
				serverError: null,
				settings: null,
			}

		case 'FOLDER_FETCH_SETTINGS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				settings: action.data,
			}

		case 'FOLDER_CHANGE_SETTING': {
			const settings = {
				...state.settings
			};

			if (!!action.subkey) {
				settings[action.key] = {
					...state.settings[action.key]
				};
				settings[action.key][action.subkey] = action.value;
			} else {
				settings[action.key] = action.value;
			}

			return {
				...state,
				settings
			}
		}

		case 'FOLDER_UPDATE_SETTINGS':
		case 'FOLDER_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				actionSuccessfully: false,
			}

		case 'FOLDER_UPDATE_SETTINGS_SUCCESS':
		case 'FOLDER_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				actionSuccessfully: true,
			}

		case 'FOLDER_FETCH_RELEVANT_ACCOUNTS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				relevantAccounts: action.data
			}

		case 'FOLDER_FETCH_PERMISSIONS_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				permissions: action.data
			}

		case 'FOLDER_PERMISSIONS_CHANGE_ACCOUNT':
			return {
				...state,
				permissions: {
					...state.permissions,
					accountsWithPermissions: state.permissions.accountsWithPermissions.map(account => {
						const permissions = (action.enabled && 'DOC_ID_REVIEW' === action.permission) ?
							['DOC_ID_REVIEW', 'DOC_ID_READ'] : [action.permission];
						return account.id === action.id ? {
							...account,
							permissions: account.permissions
								.filter(p => permissions.indexOf(p) === -1)
								.concat(action.enabled ? permissions : [])
						} : account
					})
				}
			}
		case 'FOLDER_PERMISSIONS_DELETE_ACCOUNT':
			return {
				...state,
				permissions: {
					...state.permissions,
					accountsWithPermissions: state.permissions.accountsWithPermissions
						.filter(account => account.id !== action.id)
				}
			}
		case 'FOLDER_PERMISSIONS_ADD_ACCOUNTS':
			return {
				...state,
				permissions: {
					...state.permissions,
					accountsWithPermissions: state.permissions.accountsWithPermissions
						.filter(account => !action.accounts.some(find => find.id === account.id))
						.concat(action.accounts)
				}
			}

		default:
			return state;
	}
}

export default folder;
