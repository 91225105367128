import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import {Paper, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";

class IndexComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const sessionInfo = this.props.sessionInfo;
		return <AppContainer needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{!sessionInfo && <LoadingComponent />}
				{!!sessionInfo && <Typography variant="h6">{this.props.t('notFound')}</Typography>}
			</Paper>
		</AppContainer>
	}

	onSessionCreated = () => {
		if (!!this.props.sessionInfo?.visitsAccessible) {
			this.props.history.push('/visit/overview');
		} else {
			this.props.history.push('/profile');
		}
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
		}
	},
	dispatch => {
		return {}
	}
)(IndexComponent)));
