import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";

const defaultState = {};

class CompanyAccountSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onCompanyAccountFetchSettings(this.props.accountId);
		}
	}

	render() {
		const settings = this.props.companyAccountSettings;
		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="md"
			>
				<DialogTitle>{this.props.t('company.accountSettings')}</DialogTitle>
				{!settings && <DialogContent><LoadingComponent/></DialogContent>}
				{settings && <DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('company.accountEmailHeader')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('company.accountEmail')}
									required
									value={settings.email}
									onChange={this.onChangeEmail}
									autoComplete="off"
									fullWidth
									autoFocus
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.accountLanguageHeader')}</StepLabel>
							<StepContent>
								<FormControl fullWidth>
									<Select
										value={settings.language}
										onChange={this.onChangeLanguage}
									>
										{['EN', 'NL', 'FR'].map(lang =>
											<MenuItem key={lang}
													  value={lang}>{this.props.t('lang', {lng: lang.toLowerCase()})}
											</MenuItem>)}
									</Select>
								</FormControl>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.accountPermissions')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', flexDirection: 'column'}}>
									<FormControlLabel
										control={<Checkbox checked={settings.companyAdmin}
														   onChange={this.onChangeCompanyAdmin}/>}
										label={this.props.t('company.accountCompanyAdmin')}
										sx={{m: 0}}
									/>
									<FormControlLabel
										control={<Checkbox checked={settings.companyAdmin || settings.companyInsightsViewer}
														   onChange={this.onChangeCompanyInsightsViewer}/>}
										disabled={settings.companyAdmin}
										label={this.props.t('company.accountCompanyInsightsViewer')}
										sx={{m: 0}}
									/>
								</Box>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('company.accountSettingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('company.accountSettingsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained" disabled={!this.canContinue()}
							onClick={this.onUpdate}
							id="btn-settings-save">
						{this.props.t('save')}
					</Button>
				</DialogActions>
			</Dialog>

		</Fragment>
	}

	onChangeEmail = (e) => {
		this.props.onCompanyAccountChangeSetting('email', null, e.target.value);
	}

	onChangeLanguage = (e) => {
		this.props.onCompanyAccountChangeSetting('language', null, e.target.value);
	}

	onChangeCompanyAdmin = (e, value) => {
		this.props.onCompanyAccountChangeSetting('companyAdmin', null, value);
	}

	onChangeCompanyInsightsViewer = (e, value) => {
		this.props.onCompanyAccountChangeSetting('companyInsightsViewer', null, value);
	}

	canContinue = () => {
		return !!this.props.companyAccountSettings?.email?.trim();
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onAccountUpdateSettings(this.props.companyAccountSettings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			companyAccountSettings: state.company.accountSettings,
		}
	},
	dispatch => {
		return {
			onCompanyAccountFetchSettings: (accountId) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_FETCH_SETTINGS',
					accountId
				});
			},
			onCompanyAccountChangeSetting: (key, subkey, value) => {
				dispatch({
					type: 'COMPANY_ACCOUNT_CHANGE_SETTING',
					key,
					subkey,
					value
				});
			}
		}
	}
)(CompanyAccountSettingsDialog));
