import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {createHashHistory} from "history";
import {ConnectedRouter, routerMiddleware} from "connected-react-router"
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {LicenseInfo} from "@mui/x-license-pro";

import './i18n';

import createRootReducer from './reducers';
import rootSaga from './sagas';
import App from "./components/App";

import "@dannymichel/proxima-nova";
import "@fontsource/lato";
import "@fontsource/open-sans";
import "@fontsource/titillium-web";
import './style.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

LicenseInfo.setLicenseKey('434047f15f13781ac8f8f30cad52f50bTz03NjQxMyxFPTE3Mjg2NDY4MTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const history = createHashHistory();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (process.env.REACT_APP_DEVTOOLS_ENABLED === 'true' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
    createRootReducer(history),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        ),
    ),
);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
);



