const defaultState = {
    menuOpen: (window.screen?.width || 768) >= 768,
};

const app = (state = defaultState, action) => {
    switch (action.type) {
        case 'APP_MENU_OPEN':
            return {
                ...state,
                menuOpen: true
            };

        case 'APP_MENU_CLOSE':
            return {
                ...state,
                menuOpen: false
            }

        default:
            return state;
    }
}

export default app;
