import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {withTranslation} from "react-i18next";
import {saveAs} from "file-saver";
import {IDENTIFICATION_ATTRIBUTE_TYPES} from "../common/Constants";

export const EXAMPLE_DATA = () => {
	const lines = [
		IDENTIFICATION_ATTRIBUTE_TYPES.join(','),
		'#,,Only numeric (no check),YYYY-MM-DD,,Only numeric,NL|FR|EN,male|female|x,ISO,,,,Only numeric,YYYY-MM-DD,Only numeric,,YYYY-MM-DD',
		'John,Doe,123456789,1983-01-01,john.doe@dioss.com,0475123456,NL,male,BEL,Koekoekstraat 70,Melle,9090,123456789,2050-01-01,123456789,123456789,2050-01-01'
	];
	return lines.join('\n');
}

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

const defaultState = {
	file: null
}

class CompanySandboxAccountImportDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{this.props.t('company.sandboxAccountImport')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.sandboxAccountImportPrepareTitle')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.sandboxAccountImportPrepareDescription')}</Typography>
							<Button
								variant="contained"
								size="small"
								onClick={this.onDownloadExample}
								startIcon={<CloudDownloadIcon/>}
								sx={{mt: 1}}
							>
								{this.props.t('company.sandboxAccountImportUploadExample')}
							</Button>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.sandboxAccountImportUploadTitle')}</StepLabel>
						<StepContent>
							<Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
								<Button
									component="label"
									role={undefined}
									variant="contained"
									size="small"
								>
									{this.props.t('company.sandboxAccountImportUploadDescription')}
									<VisuallyHiddenInput
										type="file"
										onChange={this.onChangeFiles}
									/>
								</Button>
								<Typography variant="body2">{this.state.file?.name}</Typography>
							</Box>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-import-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!this.canContinue()} onClick={this.onImport}
						id="btn-import-confirm">
					{this.props.t('company.sandboxAccountImport')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeFiles = (e) => {
		this.setState({file: e.target.files?.[0]});
	}

	canContinue = () => {
		return !!this.state.file;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onDownloadExample = (allowContacts) => {
		const blob = new Blob([EXAMPLE_DATA()], {type: 'text/plain;charset=utf-8'});
		saveAs(blob, "Sandbox_Example.csv");
	}

	onImport = () => {
		const formData = new FormData();
		formData.append('file', this.state.file);
		this.props.onImport(formData);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanySandboxAccountImportDialog));
