import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box, Button, IconButton, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import CompanyExpiryKeyCreateDialog from "./CompanyExpiryKeyCreateDialog";
import CompanyExpiryKeyUpdateDialog from "./CompanyExpiryKeyUpdateDialog";
import {differenceInDays, format, isValid, startOfDay} from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import ServerErrorComponent from "../common/ServerErrorComponent";

const defaultState = {
	// list
	filterValue: '',
	page: 0,
	pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
	sortModel: [],
	fieldFilters: {},

	// dialogs
	createDialogOpen: false,
	updateDialogOpen: false,
	revokeDialogOpen: false,

	// active row element
	activeCompanyApiKeyId: null
};

const MAP_FILTERS = (fieldFilters) => {
	const formatDate = (date) => {
		if (isValid(date)) {
			return format(date, 'yyyy-LL-dd');
		} else {
			return undefined;
		}
	};
	return {
		validFromFilter: formatDate(fieldFilters.validFrom),
		validUntilFilter: formatDate(fieldFilters.validUntil)
	};
}

class CompanyApiKeyExpiryKeyOverviewComponent extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyApiKeysActionSuccessfully && !prevProps.companyApiKeysActionSuccessfully) {
			this.onCompanyFetchApiKeys();
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onCompanyFetchApiKeys());
	}

	render() {
		const busy = this.props.companyBusy &&
			!this.state.updateDialogOpen;

		const dataGridColumns = [
			{
				field: 'value',
				headerName: this.props.t('company.apiKeyValue'),
				editable: false,
				sortable: false,
				flex: 2,
			},
			{
				field: 'description',
				headerName: this.props.t('company.expiryKeyDescription'),
				editable: false,
				flex: 1,
			},
			{
				field: 'validFrom',
				headerName: this.props.t('company.expiryKeyValidFrom'),
				editable: false,
				flex: 1,
				valueGetter: (data) => !!data.value ? format(new Date(data.value), "dd-LL-yyyy") : ''
			},
			{
				field: 'validUntil',
				headerName: this.props.t('company.expiryKeyValidUntil'),
				editable: false,
				flex: 1,
				valueGetter: (data) => !!data.value ? format(new Date(data.value), "dd-LL-yyyy") : ''
			},

			{
				field: 'state',
				headerName: this.props.t('company.expiryKeyState'),
				editable: false,
				sortable: false,
				flex: 1,
				renderCell: (cellValues) => this.onDetermineState(startOfDay(new Date(cellValues.row.validFrom)), startOfDay(new Date(cellValues.row.validUntil)))
			},
			{
				field: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				align: 'right',
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.apiKeySettings')}
						id={"btn-edit-" + cellValues.id}
						onClick={() => {
							this.setState({activeCompanyKeyId: cellValues.row.id});
							this.onUpdateDialogOpen();
						}}>
						<EditIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.apiKeyRevoke')}
						id={"btn-delete-" + cellValues.id}
						onClick={() => {
							this.setState({activeCompanyApiKeyId: cellValues.row.id});
							this.onRevokeDialogOpen();
						}}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</Fragment>)
			},
		];

		return <Box>
			<Typography variant="h6">{this.props.t('company.apiKeyHeader')}</Typography>
			<Typography>{this.props.t('company.apiKeyHeaderDescription')}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError} />
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
				<Button variant="contained"
						onClick={this.onCreateDialogOpen}
						startIcon={<AddIcon/>}
						sx={{mr: 1}}
						disabled={busy}
						id="btn-account-create"
				>
					{this.props.t('company.apiKeyCreate')}
				</Button>
			</Box>
			<DataGrid
				autoHeight
				disableColumnSelector
				columns={dataGridColumns}
				slots={{toolbar: FlexibleToolbar}}
				slotProps={{
					toolbar: {
						filterId: 'input-account-overview-search-text',
						filterValue: this.state.filterValue,
						onChangeFilterValue: this.onFilterValueChange,
						fieldFilters: [{
							field: 'validFrom',
							name: this.props.t('company.expiryKeyValidFrom'),
							mode: 'date',
						}, {
							field: 'validUntil',
							name: this.props.t('company.expiryKeyValidUntil'),
							mode: 'date',
						}],
						fieldFiltersValues: this.state.fieldFilters,
						onChangeFieldFilter: this.onChangeFieldFilter
					},
				}}

				loading={busy}

				pagination
				paginationMode="server"
				paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
				onPaginationModelChange={this.onPaginationModelChange}
				pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

				sortingMode="server"
				sortModel={this.state.sortModel}
				onSortModelChange={this.onSortModelChange}

				disableColumnFilter
				disableRowSelectionOnClick

				rows={this.props.companyApiKeysList}
				rowCount={this.props.companyApiKeysCount}
				density="compact"/>

			<CompanyExpiryKeyCreateDialog
				open={this.state.createDialogOpen}
				onClose={this.onCreateDialogClose}
				onCreate={this.onApiKeyCreate}
				title={this.props.t('company.apiKeyCreate')}
				expiryKey={this.props.companyApiKey}/>

			<CompanyExpiryKeyUpdateDialog
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onUpdate={this.onApiKeyUpdate}
				title={this.props.t('company.apiKeySettings')}
				expiryKey={this.props.companyApiKeysList?.find((key) => key.id === this.state.activeCompanyKeyId)}/>

			<ConfirmationDialog
				title={this.props.t('company.apiKeyRevoke')}
				confirm={this.props.t('company.apiKeyRevokeConfirm')}
				open={this.state.revokeDialogOpen}
				onClose={this.onRevokeDialogClose}
				onConfirm={this.onApiKeyRevoke}/>
		</Box>
	}

	onDetermineState = (validFrom, validUntil) => {
		const currentDay = startOfDay(new Date());

		if (currentDay >= validFrom && currentDay <= validUntil) {
			const diffInDays = differenceInDays(validUntil, currentDay) + 1; // (increment with 1 because the valid until date is also a valid date)
			if (diffInDays <= 0) {
				return this.props.t('company.expiryKeyStateExpired');
			} else if (diffInDays < 10) {
				if (diffInDays === 1) {
					return this.props.t('company.expiryKeyAlmostExpireDay', { day:  diffInDays });
				} else {
					return this.props.t('company.expiryKeyAlmostExpireDays', { days:  diffInDays });
				}
			} else {
				return this.props.t('company.expiryKeyStateActive');
			}
		} else if (currentDay > validUntil) {
			return this.props.t('company.expiryKeyStateExpired');
		} else {
			return this.props.t('company.expiryKeyStateNotActive');
		}
	}

	onCreateDialogOpen = () => {
		this.setState({createDialogOpen: true});
	}

	onCreateDialogClose = () => {
		this.setState({createDialogOpen: false}, () => this.props.onCompanyApiKeyDialogReset());
	}

	onApiKeyCreate = (request) => {
		this.props.onCompanyApiKeyCreate(request);
	}

	onUpdateDialogOpen = () => {
		this.setState({updateDialogOpen: true});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onApiKeyUpdate = (request) => {
		const companyApiKeyId = this.state.activeCompanyKeyId;
		this.setState({
			activeCompanyKeyId: null,
			updateDialogOpen: false
		}, () => this.props.onCompanyApiKeyUpdate(companyApiKeyId, request));
	}

	onRevokeDialogOpen = () => {
		this.setState({revokeDialogOpen: true});
	}

	onRevokeDialogClose = () => {
		this.setState({revokeDialogOpen: false});
	}

	onApiKeyRevoke = () => {
		const companyApiKeyId = this.state.activeCompanyApiKeyId;
		this.setState({
			activeCompanyApiKeyId: null,
			revokeDialogOpen: false
		}, () => this.props.onCompanyApiKeyRevoke(companyApiKeyId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanyFetchApiKeys)
	}

	onChangeFieldFilter = (field, value) => {
		const fieldFilters = {...this.state.fieldFilters};
		if (!value) {
			delete fieldFilters[field];
		} else {
			fieldFilters[field] = value;
		}
		this.setState({
			fieldFilters
		}, this.onCompanyFetchApiKeys);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchApiKeys)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchApiKeys);
	}

	onCompanyFetchApiKeys = () => {
		this.props.onCompanyFetchApiKeys({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			...MAP_FILTERS(this.state.fieldFilters),
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,

			companyApiKey: state.company.companyApiKey,
			companyApiKeysList: state.company.companyApiKeysList,
			companyApiKeysCount: state.company.companyApiKeysCount,
			companyApiKeysActionSuccessfully: state.company.companyApiKeysActionSuccessfully,
		}
	},
	dispatch => {
		return {
			onCompanyFetchApiKeys: (request) => {
				dispatch({
					type: 'COMPANY_APIKEY_FETCH',
					request
				});
			},
			onCompanyApiKeyCreate: (request) => {
				dispatch({
					type: 'COMPANY_APIKEY_CREATE',
					request
				});
			},
			onCompanyApiKeyUpdate: (companyApiKeyId, request) => {
				dispatch({
					type: 'COMPANY_APIKEY_UPDATE',
					companyApiKeyId,
					request
				});
			},
			onCompanyApiKeyRevoke: (companyApiKeyId) => {
				dispatch({
					type: 'COMPANY_APIKEY_REVOKE',
					companyApiKeyId
				});
			},
			onCompanyApiKeyDialogReset: () => {
				dispatch({
					type: 'COMPANY_APIKEY_DIALOG_RESET'
				})
			}
		}
	}
)(CompanyApiKeyExpiryKeyOverviewComponent));
