import React, {Component} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default class PasswordTextField extends Component {

    constructor(props) {
        super(props);
        this.state = { showPassword: false };
    }

    render() {
        return <TextField {...this.props}
              type={this.state.showPassword ? 'text' : 'password'}
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              tabIndex="-1"
                              aria-label="toggle password visibility"
                              onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                  )
              }}
        />
    }

}

