import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	email: '',
	language: 'EN',
}

class CompanyAccountInvitationDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.accountInvite')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.accountEmailHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.accountEmail')}
								required
								value={this.state.email}
								onChange={this.onChangeEmail}
								autoComplete="off"
								fullWidth
								autoFocus
								size="small"
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.accountLanguageHeader')}</StepLabel>
						<StepContent>
							<FormControl fullWidth size="small">
								<Select
									value={this.state.language}
									onChange={this.onChangeLanguage}
								>
									{['EN', 'NL', 'FR'].map(lang =>
										<MenuItem key={lang}
												  value={lang}>{this.props.t('lang', {lng: lang.toLowerCase()})}
										</MenuItem>)}
								</Select>
							</FormControl>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.accountInviteFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.accountInviteFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-invite-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!this.canContinue()} onClick={this.onInvite}
						id="btn-invite-confirm">
					{this.props.t('company.accountInvite')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeEmail = (e) => {
		this.setState({email: e.target.value});
	}

	onChangeLanguage = (e) => {
		this.setState({language: e.target.value});
	}

	canContinue = () => {
		return !!this.state.email.trim();
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onInvite();
		}
	}

	onInvite = () => {
		const {email, language} = this.state;
		this.props.onInvite({email, language});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(CompanyAccountInvitationDialog));
