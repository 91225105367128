import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import CompanyOverviewComponent from "../company/CompanyOverviewComponent";

const TAB_COMPANY_OVERVIEW = { key: 'COMPANY_OVERVIEW', translateText: (props) => props.t('admin.tab_COMPANY_OVERVIEW')}

class AdminSectionComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.eligibleTabs.length > 0) {
			this.setState({tab: this.props.eligibleTabs.at(0)});
		}
	}

	render() {
		return <AppContainer withMenu needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.eligibleTabs.map(tab => <Tab key={tab.key} value={tab} label={tab.translateText(this.props)}/>)}
					</Tabs>

					{this.renderCurrentTab()}
				</>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case TAB_COMPANY_OVERVIEW:
				return <CompanyOverviewComponent />;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({ tab });
	}

}

export default withTranslation()(connect(
	state => {
		const eligibleTabs = [];
		if (state.session?.info?.companyOverviewAccessible) {
			eligibleTabs.push(TAB_COMPANY_OVERVIEW);
		}
		return {
			sessionInfo: state.session.info,
			eligibleTabs
		}
	},
	dispatch => {
		return {
		}
	}
)(AdminSectionComponent));
