import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Card, CardActions, CardContent, CardMedia, Paper, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import AppContainer from "../common/AppContainer";

class SessionSwitchCompanyComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const maybeCurrentCompanyEntry = this.props.sessionCompanyList
			.filter(entry => entry.id === this.props.sessionInfo.companyId)?.[0];

		return <AppContainer withMenu needsSession onSessionCreated={this.onSessionCreated}>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<ServerErrorComponent serverError={this.props.sessionServerError} />

				{this.props.sessionCompanyList?.length > 0 && <>
					{!!maybeCurrentCompanyEntry && <>
						<Typography variant="h6">{this.props.t('session.switchCurrentCompany')}</Typography>
						<Box sx={{ mb: 2, display: 'flex' }}>
							{this.renderCompanyBox(maybeCurrentCompanyEntry)}
						</Box>
					</>}

					<Typography variant="h6">{this.props.t('session.switchToOtherCompany')}</Typography>
					<Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
						{this.props.sessionCompanyList
							.filter(entry => entry.id !== this.props.sessionInfo.companyId)
							.map(this.renderCompanyBox)}
					</Box>
				</>}
			</Paper>
		</AppContainer>
	}

	onSessionCreated = () => {
		this.props.onSessionFetchCompanyList();
	}

	renderCompanyBox = (entry) => {
		return <Card key={entry.id} variant="outlined" sx={{ mr: 2, mt: 2 }}>
			{!!entry.logoBase64 && <CardMedia
				component="img"
				height="50"
				image={'data:image/png;base64,' + entry.logoBase64}
				sx={{ pl: 2, pr: 2, pt: 1 }}
			/>}
			<CardContent>
				<Typography sx={{ fontSize: 14 }} component="div">
					{entry.name}
				</Typography>
			</CardContent>
			{(entry.id !== this.props.sessionInfo.companyId) && <CardActions>
				<Button size="small" onClick={() => this.onSwitchCompany(entry.id)}
						disabled={this.props.sessionBusy}
						id="btn-session-company-switch"
				>
					{this.props.t('session.switchTo')}
				</Button>
			</CardActions>}
		</Card>;
	}

	onSwitchCompany = (id) => {
		this.props.onSessionSwitchCompany({ companyId: id });
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionBusy: state.session.busy,
			sessionServerError: state.session.serverError,
			sessionInfo: state.session.info,
			sessionCompanyList: state.session.companyList,
		}
	},
	dispatch => {
		return {
			onSessionFetchCompanyList: () => {
				dispatch({
					type: 'SESSION_FETCH_COMPANY_LIST'
				});
			},
			onSessionSwitchCompany: (request) => {
				dispatch({
					type: 'SESSION_SWITCH_COMPANY',
					request
				});
			}
		}
	}
)(SessionSwitchCompanyComponent));
