import React, {Component} from "react";
import {connect} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Box, Link} from "@mui/material";
import SessionContainer from "./SessionContainer";

class ResetPasswordContainer extends Component {

    render() {
        return <SessionContainer title={this.props.t('session.resetPassword')}>
            {this.props.children}
            <Box sx={{mt: 1, display: 'flex', flexDirection: 'column'}}>
                <Link component={RouterLink} to="/login" variant="body2">
                    {this.props.t('session.resetPasswordToLogin')}
                </Link>
            </Box>
        </SessionContainer>
    }

}

export default withTranslation()(connect(
    state => {
        return {}
    },
    dispatch => {
        return {}
    }
)(ResetPasswordContainer));
