import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Button, IconButton, Paper, Tab, Tabs, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import {ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import FlexibleToolbar from "../common/FlexibleToolbar";
import ServerErrorComponent from "../common/ServerErrorComponent";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CompanySandboxAccountCreateDialog from "./CompanySandboxAccountCreateDialog";
import CompanySandboxAccountImportDialog from "./CompanySandboxAccountImportDialog";
import CompanySandboxAccountUpdateDialog from "./CompanySandboxAccountUpdateDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";

class CompanySandboxComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [],

			// dialogs
			createDialogOpen: false,
			importDialogOpen: false,
			updateAccountId: null,
			deleteAccountId: null,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companySandboxActionSuccessFully && !prevProps.companySandboxActionSuccessFully) {
			this.onCompanySandboxAccountFetchOverviewList();
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, this.onCompanySandboxAccountFetchOverviewList);
	}

	render() {
		const busy = this.props.companyBusy;

		const dataGridColumns = [
			{
				field: 'firstName',
				headerName: this.props.t('company.sandboxAccountFirstName'),
				editable: false,
				flex: 1
			},
			{
				field: 'lastName',
				headerName: this.props.t('company.sandboxAccountLastName'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 90,
				align: 'right',
				renderCell: (cellValues) => (<>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.sandboxAccountUpdate')}
						onClick={() => this.setState({updateAccountId: cellValues.row.id})}
					>
						<EditIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.sandboxAccountDelete')}
						onClick={() => this.setState({deleteAccountId: cellValues.row.id})}
					>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</>)
			},
		];

		return <Box>
			<Typography variant="h6">{this.props.t('company.sandboxHeader')}</Typography>
			<Typography>{this.props.t('company.sandboxHeaderDescription')}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1, gap: 1}}>
				<Button
					variant="contained"
					onClick={this.onCreationDialogOpen}
					startIcon={<AddIcon/>}
					disabled={busy}
					id="btn-sandbox-account-create"
				>
					{this.props.t('company.sandboxAccountCreate')}
				</Button>
				<Button
					variant="contained"
					onClick={this.onImportDialogOpen}
					startIcon={<CloudUploadIcon/>}
					disabled={busy}
					id="btn-sandbox-account-import"
				>
					{this.props.t('company.sandboxAccountImport')}
				</Button>
			</Box>
			<DataGrid
				autoHeight
				disableColumnSelector
				columns={dataGridColumns}
				slots={{toolbar: FlexibleToolbar}}
				slotProps={{
					toolbar: {
						filterId: 'input-company-overview-search-text',
						filterValue: this.state.filterValue,
						onChangeFilterValue: this.onFilterValueChange,
					}
				}}

				loading={busy}

				pagination
				paginationMode="server"
				paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
				onPaginationModelChange={this.onPaginationModelChange}
				pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

				sortingMode="server"
				sortModel={this.state.sortModel}
				onSortModelChange={this.onSortModelChange}

				disableColumnFilter
				disableRowSelectionOnClick

				rows={this.props.companySandboxAccountOverviewList}
				rowCount={this.props.companySandboxAccountOverviewCount}
				density="compact"
			/>
			<CompanySandboxAccountCreateDialog
				open={this.state.createDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onCreate}
			/>
			<CompanySandboxAccountImportDialog
				open={this.state.importDialogOpen}
				onClose={this.onImportDialogClose}
				onImport={this.onImport}
			/>
			<CompanySandboxAccountUpdateDialog
				open={!!this.state.updateAccountId}
				sandboxAccountId={this.state.updateAccountId}
				onClose={this.onUpdateDialogClose}
				onUpdate={this.onUpdate}
			/>
			<ConfirmationDialog
				open={!!this.state.deleteAccountId}
				title={this.props.t('company.sandboxAccountDelete')}
				confirm={this.props.t('company.sandboxAccountDeleteConfirm')}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onCompanySandboxAccountDelete}/>
		</Box>
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, this.onCompanySandboxAccountFetchOverviewList);
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanySandboxAccountFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanySandboxAccountFetchOverviewList);
	}

	onCreationDialogOpen = () => {
		this.setState({createDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({createDialogOpen: false});
	}

	onCreate = (request) => {
		this.setState({createDialogOpen: false}, () => this.props.onCompanySandboxAccountCreate(request));
	}

	onImportDialogOpen = () => {
		this.setState({importDialogOpen: true});
	}

	onImportDialogClose = () => {
		this.setState({importDialogOpen: false});
	}

	onImport = (request) => {
		this.setState({importDialogOpen: false}, () => this.props.onCompanySandboxAccountImport(request));
	}

	onUpdateDialogClose = () => {
		this.setState({updateAccountId: null});
	}

	onUpdate = (request) => {
		const accountId = this.state.updateAccountId;
		this.setState({updateAccountId: null}, () => this.props.onCompanySandboxAccountUpdate(accountId, request));
	}

	onDeleteDialogClose = () => {
		this.setState({deleteAccountId: null});
	}

	onCompanySandboxAccountDelete = () => {
		const accountId = this.state.deleteAccountId;
		this.setState({deleteAccountId: null}, () => this.props.onCompanySandboxAccountDelete(accountId));
	}

	onCompanySandboxAccountFetchOverviewList = () => {
		this.props.onCompanySandboxAccountFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companySandboxAccountOverviewList: state.company.sandboxAccountOverviewList,
			companySandboxAccountOverviewCount: state.company.sandboxAccountOverviewCount,
			companySandboxActionSuccessFully: state.company.sandboxActionSuccessFully,
		}
	},
	dispatch => {
		return {
			onCompanySandboxAccountFetchOverviewList: (request) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onCompanySandboxAccountCreate: (request) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_CREATE',
					request
				});
			},
			onCompanySandboxAccountImport: (request) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_IMPORT',
					request
				});
			},
			onCompanySandboxAccountUpdate: (sandboxAccountId, request) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_UPDATE',
					sandboxAccountId,
					request
				});
			},
			onCompanySandboxAccountDelete: (sandboxAccountId) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_DELETE',
					sandboxAccountId
				});
			}
		}
	}
)(CompanySandboxComponent));
