const defaultState = {
	// general state
	busy: false,
	serverError: false,
};

const admin = (state = defaultState, action) => {
	switch (action.type) {
		case 'ADMIN_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'ADMIN_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null
			}
		default:
			return state;
	}
}

export default admin;
