import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import InfoGeneralComponent from "./InfoGeneralComponent";
import InfoVersionComponent from "./InfoVersionComponent";

const TAB_INFO_GENERAL = { key: 'GENERAL', translateText: (props) => props.t('info.infoTab_GENERAL')};
const TAB_INFO_VERSION = { key: 'VERSION', translateText: (props) => props.t('info.infoTab_VERSION')};

class InfoComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.tabs.length > 0) {
			this.setState({tab: this.props.tabs.at(0)});
		}
	}

	render() {
		return <AppContainer withMenu needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.tabs.map(tab => <Tab key={tab.key} value={tab} label={tab.translateText(this.props)}/>)}
					</Tabs>
					<Box sx={{mt: 2, mb: 2}}>
						{this.renderCurrentTab()}
					</Box>
				</>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case TAB_INFO_GENERAL:
				return <InfoGeneralComponent/>;
			case TAB_INFO_VERSION:
				return <InfoVersionComponent/>;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({tab});
	}
}

export default withTranslation()(connect(
	state => {
		return {
			tabs: [TAB_INFO_GENERAL, TAB_INFO_VERSION]
		}
	},
	dispatch => {
		return {}
	}
)(InfoComponent));
