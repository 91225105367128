import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {
	Box,
	Button, Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl, FormControlLabel, FormGroup,
	InputLabel, MenuItem,
	Select, TextField, Typography
} from "@mui/material";

const defaultState = {
	phoneNumber: '',
	language: 'EN',
	companyFolderId: null,
	methods: []
};

class VisitRemoteIdSessionCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				...defaultState,
				companyFolderId: this.props.folders?.filter(folder => folder.remoteIdCreatable)?.[0]?.id
			});
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{this.props.t('visit.remoteIdCreateDialogTitle')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 3, pt: 1}}>
					<TextField
						variant="outlined"
						label={this.props.t('visit.phoneNumber')}
						required
						value={this.state.phoneNumber}
						onChange={this.onChangePhoneNumber}
						autoComplete="off"
						fullWidth
						autoFocus
					/>

					<FormControl>
						<InputLabel
							id="visit-create-language">{this.props.t('visit.language')}</InputLabel>
						<Select
							labelId="visit-create-language"
							label={this.props.t('visit.language')}
							value={this.state.language}
							onChange={this.onChangeLanguage}
						>
							{['EN', 'NL', 'FR'].map(lang =>
								<MenuItem key={lang}
										  value={lang}>{this.props.t('lang', {lng: lang.toLowerCase()})}
								</MenuItem>)}
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel
							id="visit-company-folder"
						>
							{this.props.t('visit.companyFolderName')}
						</InputLabel>
						<Select
							labelId="visit-company-folder"
							label={this.props.t('visit.companyFolderName')}
							value={this.state.companyFolderId || ''}
							onChange={this.onChangeCompanyFolderId}
						>
							{this.props?.folders?.filter(folder => folder.remoteIdCreatable)?.map(folder =>
								<MenuItem key={folder.id} value={folder.id}>{folder.name}</MenuItem>
							)}
						</Select>
					</FormControl>

					<Box>
						<Typography variant="body2">{this.props.t('visit.methodHeader')}</Typography>
						<FormGroup>
							{['ITSME', 'MYID', 'DOC_ID'].map(method =>
								<FormControlLabel
									key={method}
									control={
										<Checkbox checked={this.state.methods.indexOf(method) >= 0}
												  onChange={(e, enabled) => this.onChangeMethod(method, enabled)}
												  name={method}/>
									}
									label={this.props.t('visit.method_' + method)}
									sx={{p: 0}}
								/>
							)}
						</FormGroup>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					id="btn-visit-cancel"
					onClick={this.onClose}
				>
					{this.props.t('cancel')}
				</Button>
				<Button
					id="btn-visit-create-confirm"
					variant="contained"
					disabled={!this.canContinue()}
					onClick={this.onCreate}
				>
					{this.props.t('visit.create')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	canContinue = () => {
		return !!this.state.phoneNumber && this.state.methods.length > 0;
	}

	onChangePhoneNumber = (e) => {
		const value = e.target.value;
		this.setState({phoneNumber: value.replace(/[^\+0-9]+/g, '')});
	}

	onChangeLanguage = (e) => {
		this.setState({language: e.target.value});
	}

	onChangeCompanyFolderId = (e) => {
		this.setState({companyFolderId: e.target.value});
	}

	onChangeMethod = (method, enabled) => {
		const methods = this.state.methods
			.filter(search => method !== search);
		if (enabled) {
			methods.push(method);
		}
		this.setState({methods});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		this.props.onCreate({...this.state});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(VisitRemoteIdSessionCreateDialog);
