import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import {ReactComponent as Logo} from "../../img/logo.svg";
import diossLogo from "../../img/dioss-logo.svg";
import {styled} from "@mui/material/styles";

const StyledLinearProgress = styled(LinearProgress)(() => ({
	height: 4,
	backgroundColor: '#f5f7ff',
}));

class SessionContainer extends Component {

	render() {
		return <Box sx={{
			backgroundColor: '#f2f2f2',
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 1
		}} maxWidth="false">
			<Box sx={{flexGrow: 1}}></Box>
			<Container maxWidth="sm">
				<Paper variant="outlined" sx={{
					display: 'flex',
					flexDirection: 'column',
					pt: 3,
					pb: 3,
					pl: {xs: 2, sm: 3},
					pr: {xs: 2, sm: 3},
				}}>
					{<Box sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'flex-end',

						pb: 3,
						pt: 2,
						flexWrap: 'wrap'
					}}>
						<Logo style={{width: 100, height: 50}}/>
					</Box>}

					{/* progress acts as line */}
					{!!this.props.progress && <Box>
						<StyledLinearProgress variant="determinate" value={this.props.progress}/>
					</Box>}
					{!this.props.progress && <Box sx={{borderBottom: '1px solid #EFEFEF'}}/>}

					{this.props.title && <Box sx={{display: 'flex', justifyContent: 'center', mt: 2, mb: 1}}>
						<Typography component="h1" variant="h5">{this.props.title}</Typography>
					</Box>}

					{this.props.children}
				</Paper>
			</Container>
			<Box sx={{flexGrow: 1}}></Box>
			<Box sx={{height: '90px', p: 1, display: 'flex', width: '100%', justifyContent: 'space-between', gap: 9}}>

				<Box sx={{display: 'flex', flexWrap: 'no-wrap', minWidth: '150px', maxWidth: '200px'}}>
					<img
						src={diossLogo}
						alt="Dioss Smart Solutions"
						style={{maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}}
					/>
				</Box>
			</Box>
		</Box>
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(SessionContainer));
