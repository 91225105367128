import React, {Component} from "react";
import {Alert, Box} from "@mui/material";
import {withTranslation} from "react-i18next";

const SERVER_ERRORS = {
	GENERAL: {translateText: (props) => props.t('serverError.GENERAL')},
	ACCOUNT_BLOCKED: {translateText: (props) => props.t('serverError.ACCOUNT_BLOCKED')},
	ACCOUNT_DEACTIVATED: {translateText: (props) => props.t('serverError.ACCOUNT_DEACTIVATED')},
	ACCOUNT_INVALID_STATE: {translateText: (props) => props.t('serverError.ACCOUNT_INVALID_STATE')},
	ACCOUNT_NOT_FOUND: {translateText: (props) => props.t('serverError.ACCOUNT_NOT_FOUND')},
	CHALLENGE_MISSING: {translateText: (props) => props.t('serverError.CHALLENGE_MISSING')},
	COMPANY_EXPIRYKEY_NOT_FOUND: {translateText: (props) => props.t('serverError.COMPANY_EXPIRYKEY_NOT_FOUND')},
	COMPANY_NOT_FOUND: {translateText: (props) => props.t('serverError.COMPANY_NOT_FOUND')},
	COMPANY_UNABLE_TO_DELETE: {translateText: (props) => props.t('serverError.COMPANY_UNABLE_TO_DELETE')},
	COMPANY_FOLDER_ALREADY_EXISTS: {translateText: (props) => props.t('serverError.COMPANY_FOLDER_ALREADY_EXISTS')},
	COMPANY_FOLDER_NOT_FOUND: {translateText: (props) => props.t('serverError.COMPANY_FOLDER_NOT_FOUND')},
	COMPANY_ITSME_CONFIG_NOT_FOUND: {translateText: (props) => props.t('serverError.COMPANY_ITSME_CONFIG_NOT_FOUND')},
	EID_INVALID_RN_DATA: {translateText: (props) => props.t('serverError.EID_INVALID_RN_DATA')},
	EID_TOKEN_NOT_FOUND: {translateText: (props) => props.t('serverError.EID_TOKEN_NOT_FOUND')},
	EMAIL_IN_USE: {translateText: (props) => props.t('serverError.EMAIL_IN_USE')},
	EMAIL_INVALID: {translateText: (props) => props.t('serverError.EMAIL_INVALID')},
	EMAIL_PROCESSING_ERROR: {translateText: (props) => props.t('serverError.EMAIL_PROCESSING_ERROR')},
	SMS_SEND_ERROR: {translateText: (props) => props.t('serverError.SMS_SEND_ERROR')},
	INTERNAL_VISIT_INVALID_STATE: {translateText: (props) => props.t('serverError.INTERNAL_VISIT_INVALID_STATE')},
	INTERNAL_VISIT_NO_DATA: {translateText: (props) => props.t('serverError.INTERNAL_VISIT_NO_DATA')},
	INTERNAL_VISIT_NOT_FOUND: {translateText: (props) => props.t('serverError.INTERNAL_VISIT_NOT_FOUND')},
	INTERNAL_VISIT_NOT_IDENTIFIED: {translateText: (props) => props.t('serverError.INTERNAL_VISIT_NOT_IDENTIFIED')},
	INVALID_PAYLOAD: {translateText: (props) => props.t('serverError.INVALID_PAYLOAD')},
	ITSME_ERROR: {translateText: (props) => props.t('serverError.ITSME_ERROR')},
	LOGIN_FAILED: {translateText: (props) => props.t('serverError.LOGIN_FAILED')},
	NOT_ALLOWED: {translateText: (props) => props.t('serverError.NOT_ALLOWED')},
	OIDC_ERROR: {translateText: (props) => props.t('serverError.OIDC_ERROR')},
	OIDC_INSTANCE_NOT_FOUND: {translateText: (props) => props.t('serverError.OIDC_INSTANCE_NOT_FOUND')},
	PASSWORDS_DO_NOT_MATCH: {translateText: (props) => props.t('serverError.PASSWORDS_DO_NOT_MATCH')},
	PASSWORD_INVALID: {translateText: (props) => props.t('serverError.PASSWORD_INVALID')},
	PASSWORD_REQUIREMENTS_ERROR: {translateText: (props) => props.t('serverError.PASSWORD_REQUIREMENTS_ERROR')},
	PASSWORD_RESET_CHALLENGE_EXPIRED: {translateText: (props) => props.t('serverError.PASSWORD_RESET_CHALLENGE_EXPIRED')},
	SECRET_MISSING: {translateText: (props) => props.t('serverError.SECRET_MISSING')},
	IDENTITY_ATTRIBUTE_NOT_FOUND: {translateText: (props) => props.t('serverError.IDENTITY_ATTRIBUTE_NOT_FOUND')},
	IDENTITY_ATTRIBUTE_VERIFICATION_EXPIRED: {translateText: (props) => props.t('serverError.IDENTITY_ATTRIBUTE_VERIFICATION_EXPIRED')},
	IDENTITY_ATTRIBUTE_VERIFICATION_FAILED: {translateText: (props) => props.t('serverError.IDENTITY_ATTRIBUTE_VERIFICATION_FAILED')},
	IMAGE_INVALID_SIZE: {translateText: (props) => props.t('serverError.IMAGE_INVALID_SIZE')},
	IMAGE_INVALID_MIME_TYPE: {translateText: (props) => props.t('serverError.IMAGE_INVALID_MIME_TYPE')},

	DOC_ID_QR_CODE_PROBLEM: {translateText: (props) => props.t('serverError.DOC_ID_QR_CODE_PROBLEM')},
	DOC_ID_SESSION_NOT_FOUND: {translateText: (props) => props.t('serverError.DOC_ID_SESSION_NOT_FOUND')},
	DOC_ID_SESSION_INVALID_STATE: {translateText: (props) => props.t('serverError.DOC_ID_SESSION_INVALID_STATE')},
	DOC_ID_MEDIA_EXTRACT_FAILED: {translateText: (props) => props.t('serverError.DOC_ID_MEDIA_EXTRACT_FAILED')},
	DOC_ID_INVALID_MEDIA: {translateText: (props) => props.t('serverError.DOC_ID_INVALID_MEDIA')},
	DOC_ID_MEDIA_NOT_FOUND: {translateText: (props) => props.t('serverError.DOC_ID_MEDIA_NOT_FOUND')},

	REMOTE_ID_SESSION_NOT_FOUND: {translateText: (props) => props.t('serverError.REMOTE_ID_SESSION_NOT_FOUND')},
}

class ServerErrorComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const serverError = this.props.serverError;
		if (!!serverError) {
			return <Box sx={!!this.props.noMargin ? {} : {mt: 1, mb: 1}}>
				<Alert
					severity="error">{SERVER_ERRORS[serverError]?.translateText(this.props) ?? 'No server error mapping'}</Alert>
			</Box>
		} else {
			return null;
		}
	}

}

export default withTranslation()(ServerErrorComponent);
