import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Box, Button} from "@mui/material";
import ResetPasswordContainer from "./ResetPasswordContainer";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ChangePasswordComponent from "./ChangePasswordComponent";

class ResetPasswordUpdateComponent extends Component {

	constructor(props) {
		super(props);

		this.payload = this.props.match.params.payload;

		this.state = {
			password: '',
			passwordConfirmation: '',
		}
	}

	render() {
		return <ResetPasswordContainer>
			<Box component="form" onSubmit={this.onSubmit} noValidate>
				{!this.props.resetPasswordUpdateSuccess &&
					<ChangePasswordComponent
						password={this.state.password}
						onPasswordChange={this.onPasswordChange}
						passwordConfirmation={this.state.passwordConfirmation}
						onPasswordConfirmationChange={this.onPasswordConfirmationChange}
					/>
				}
				<ServerErrorComponent serverError={this.props.serverError} />
				{this.props.resetPasswordUpdateSuccess &&
					<Alert severity="success">{this.props.t('session.resetPasswordUpdateSuccess')}</Alert>}
				<Button type="submit" disabled={this.props.busy} fullWidth variant="contained"
						sx={{mt: 3, mb: 2}}>{this.props.t('next')}</Button>
			</Box>
		</ResetPasswordContainer>
	}

	onPasswordChange = (e) => {
		this.setState({password: e.target.value});
	}

	onPasswordConfirmationChange = (e) => {
		this.setState({passwordConfirmation: e.target.value});
	}

	onSubmit = (event) => {
		event.preventDefault();
		if (!this.props.resetPasswordUpdateSuccess) {
			this.props.onSessionResetPasswordUpdate(this.payload, this.state.password, this.state.passwordConfirmation);
		} else {
			this.props.onSessionResetPasswordFinish();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			busy: state.session.busy,
			serverError: state.session.serverError,
			resetPasswordUpdateSuccess: state.session.resetPasswordUpdateSuccess
		}
	},
	dispatch => {
		return {
			onSessionResetPasswordUpdate: (payload, password, passwordConfirmation) => {
				dispatch({
					type: 'SESSION_RESET_PASSWORD_UPDATE',
					payload,
					password,
					passwordConfirmation
				});
			},
			onSessionResetPasswordFinish: () => {
				dispatch({
					type: 'SESSION_RESET_PASSWORD_FINISH'
				});
			}
		}
	}
)(ResetPasswordUpdateComponent));
