import React, {Component, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Toolbar
} from "@mui/material";
import {FormatBold, FormatItalic, FormatUnderlined, Link} from "@mui/icons-material";
import ContentEditable from "react-contenteditable";
import {v4 as uuidv4} from 'uuid';
import {LANGUAGES} from "../common/Constants";

const MAX_TEXT_LENGTH = 5000;
const HEADER_OPTIONS = ['normal', 'h1', 'h2', 'h3', 'h4', 'h5'];

const EXTRACT_HTML = (state) => {
	return state.customElementsPerType
		?.[state.currentCustomElementType]
		?.[state.currentLanguage] || '';
}

const PREFIX_URL = (url) => {
	// prefix URL with // to enforce using the url as a new domain iso referencing it from the current domain
	return !url.startsWith('http') ? "//" + url : url;
}

const UNPREFIX_URL = (url) => {
	return !!url && url.startsWith('//') ? url.substring(2, url.length) : url;
}

const GENERATE_DOM_ID = () => {
	return '_gen_' + uuidv4();
}

const LinkEditDialog = ({urlHref, urlDisplayText, onClose, onEdit, onInsert}) => {

	const {t} = useTranslation();
	const [href, setHref] = useState(UNPREFIX_URL(urlHref));
	const [displayText, setDisplayText] = useState(urlDisplayText);

	const onConfirm = () => {
		const prefixedHref = PREFIX_URL(href);
		if (!!urlHref) {
			onEdit(prefixedHref, displayText)
		} else {
			onInsert(prefixedHref, displayText)
		}
	}

	const onKeyUp = (e) => {
		if (e.key === 'Enter' && !!href && !!displayText) {
			onConfirm();
		}
	}

	return <Dialog open onClose={onClose} maxWidth="sm" fullWidth onKeyUp={onKeyUp}>
		<DialogTitle>{t('company.idpFlowSettingsCustomElementsLinkEdit')}</DialogTitle>
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			margin: 2,
			gap: 2
		}}>
			<TextField
				variant="outlined"
				label="URL"
				required
				value={href || ''}
				onChange={(e) => setHref(e.target.value)}
				autoComplete="off"
				autoFocus
			/>

			<TextField
				variant="outlined"
				label="Display text"
				required
				value={displayText || ''}
				onChange={(e) => setDisplayText(e.target.value)}
				autoComplete="off"
			/>
		</Box>
		<DialogContent>
			<DialogActions>
				<Button onClick={onClose}
						id="btn-link-edit-cancel"
				>
					{t('cancel')}
				</Button>
				<Button variant="contained"
						onClick={onConfirm}
						disabled={!href || !displayText}
						id="btn-link-edit-confirm"
				>
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

class CompanyIdpFlowCustomElementsEditorDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentCustomElementType: '',
			currentLanguage: 'EN',
			customElementsPerType: {},

			// header stuff
			headerSize: HEADER_OPTIONS[0],

			// a href stuff
			linkDialogOpen: false,
			activeUrlHref: null,
			activeUrlDisplayText: null,
			activeContentEditable: null,
			activeDomId: null,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.customElements !== prevProps.customElements) {
			// transform this.props.customElements to a nested object
			let currentCustomElementType = '';
			let currentLanguage = 'EN';
			const customElementsPerType = {};
			this.props.customElements?.forEach(element => {
				if (!customElementsPerType.hasOwnProperty(element.type)) {
					customElementsPerType[element.type] = {};
				}
				const customElement = customElementsPerType[element.type];
				customElement[element.language] = element.html;
				// auto select the first filled in type
				if (!!element.html && !currentCustomElementType) {
					currentCustomElementType = element.type;
					currentLanguage = element.language;
				}
			});
			this.setState({customElementsPerType, currentCustomElementType, currentLanguage});
		}
	}

	render() {
		const hasValidType = !!this.state.currentCustomElementType;
		const hasCustomElement = this.state.customElementsPerType.hasOwnProperty(this.state.currentCustomElementType);
		const html = EXTRACT_HTML(this.state);

		return <>
			<Dialog
				open={this.props.open}
				onClose={this.onClose}
				onKeyUp={this.onKeyUp}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>{this.props.t('company.idpFlowSettingsCustomElements')}</DialogTitle>
				<DialogContent>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
						<Box sx={{display: 'flex', gap: 1}}>
							<FormControl
								size="small"
								sx={{flexGrow: 1}}
							>
								<InputLabel id="type-label">{this.props.t('company.idpFlowSettingsCustomElementsType')}</InputLabel>
								<Select
									value={this.state.currentCustomElementType}
									onChange={this.onChangeCurrentCustomElementType}
									label={this.props.t('company.idpFlowSettingsCustomElementsType')}
									labelId="type-label"
								>
									{this.props.customElementTypes?.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
								</Select>
							</FormControl>

							<Button
								variant="contained"
								color="secondary"
								disabled={!hasCustomElement || !hasValidType}
								onClick={this.onRemoveCurrentCustomElement}
							>
								{this.props.t('company.idpFlowSettingsCustomElementsRemove')}
							</Button>
						</Box>


						<Tabs
							value={this.state.currentLanguage}
							onChange={this.onChangeCurrentLanguage}
							variant="scrollable"
							scrollButtons
						>
							{LANGUAGES.map(lang => <Tab
								key={lang}
								value={lang}
								label={this.props.t('lang', {lng: lang.toLowerCase()})}
								disabled={!hasValidType}
							/>)}
						</Tabs>

						<Toolbar
							variant="dense"
							sx={{
								border: "1px solid rgba(224, 224, 224, 1)",
								borderRadius: '4px',
								mb: 0.25,
								gap: 1
							}}>
							<IconButton
								variant="contained"
								edge="start"
								color="inherit"
								disabled={!hasValidType}
								onClick={this.onFormatBold}
							>
								<FormatBold format="small"/>
							</IconButton>
							<IconButton
								variant="contained"
								color="inherit"
								disabled={!hasValidType}
								onClick={this.onFormatItalic}
							>
								<FormatItalic format="small"/>
							</IconButton>
							<IconButton
								variant="contained"
								color="inherit"
								disabled={!hasValidType}
								onClick={this.onFormatUnderline}
							>
								<FormatUnderlined format="small"/>
							</IconButton>

							<Divider orientation="vertical" flexItem/>

							<Select
								size="small"
								value={this.state.headerSize}
								onChange={this.onFormatHeaderSize}
							>
								{HEADER_OPTIONS.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
							</Select>

							<Divider orientation="vertical" flexItem/>

							<IconButton
								variant="contained"
								color="inherit"
								disabled={!hasValidType}
								onClick={this.onOpenLinkDialog}
							>
								<Link format="small"/>
							</IconButton>
						</Toolbar>

						<ContentEditable
							onChange={(e) => this.onChangeHtml(e, e?.currentTarget?.innerHTML)}
							onBlur={(e) => {
								this.onChangeHtml(e, e?.currentTarget?.innerHTML)}
							}
							onSelect={(e) => this.onSelectText(e)}
							html={html}
							disabled={!hasValidType}
							style={{
								flex: 1,
								minHeight: '75px',
								border: "1px solid rgba(224, 224, 224, 1)",
								borderRadius: "4px",
								padding: "8.5px 14px",
								textWrap: 'wrap',
								overflowWrap: 'break-word',
								height: '100%',
								...(!hasValidType && {color: 'rgba(0, 0, 0, 0.38)'})
							}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} id="btn-custom-elements-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							onClick={this.onSave}
							id="btn-custom-elements-confirm"
					>
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>

			{this.state.linkDialogOpen && <LinkEditDialog
				onClose={this.onCloseLinkDialog}
				urlHref={this.state.activeUrlHref}
				urlDisplayText={this.state.activeUrlDisplayText}
				onEdit={this.onEditLink}
				onInsert={this.onInsertLink}
			/>}
		</>
	}

	onChangeCurrentCustomElementType = (e) => {
		this.setState({currentCustomElementType: e.target.value})
	}

	onRemoveCurrentCustomElement = () => {
		if (!!this.state.currentCustomElementType) {
			const customElementsPerType = this.state.customElementsPerType;
			delete customElementsPerType[this.state.currentCustomElementType];
			this.setState({customElementsPerType});
		}
	}

	onChangeCurrentLanguage = (e, currentLanguage) => {
		this.setState({currentLanguage});
	}

	onFormatBold = () => {
		const selectedEditorText = EXTRACT_HTML(this.state);
		const boldTagRegex = /<[/]?(b)>/gi;

		let changed;
		if (boldTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(boldTagRegex, '');
		} else {
			changed = '<b>' + selectedEditorText + '</b>';
		}

		this.onChangeHtml(null, changed);
	}

	onFormatItalic = () => {
		const selectedEditorText = EXTRACT_HTML(this.state);
		const italicTagRegex = /<[/]?(i)>/gi;

		let changed;
		if (italicTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(italicTagRegex, '');
		} else {
			changed = '<i>' + selectedEditorText + '</i>';
		}

		this.onChangeHtml(null, changed);
	}

	onFormatUnderline = () => {
		const selectedEditorText = EXTRACT_HTML(this.state);
		const underlineTagRegex = /<[/]?(u)>/gi;

		let changed;
		if (underlineTagRegex.test(selectedEditorText)) {
			changed = selectedEditorText.replaceAll(underlineTagRegex, '');
		} else {
			changed = '<u>' + selectedEditorText + '</u>';
		}

		this.onChangeHtml(null, changed);
	}

	onFormatHeaderSize = (e) => {
		const value = e.target.value;

		const headerTagRegex = /<[/]?(h[1-5])>/gi;
		const selectedEditorText = EXTRACT_HTML(this.state)?.replaceAll(headerTagRegex, '');;

		let changed;
		if ('normal' === value) {
			changed = selectedEditorText;
		} else {
			changed = `<${value}>${selectedEditorText}</${value}>`
		}

		this.setState({headerSize: value}, () => this.onChangeHtml(null, changed));
	}

	onSelectText = (e) => {
		const el = e.nativeEvent.srcElement;
		if (el.localName === 'a') {
			// try to obtain the 'raw' href from the html iso using the DOM, since the DOM will parse (prefix) the href
			const anchorRawHtml = el.outerHTML;
			const matcher = anchorRawHtml.matchAll(/(href=")(.*?)(")/g);
			const rawHref = Array.from(matcher)?.[0]?.[2];

			this.setState({
				activeContentEditable: el.parentNode.cloneNode(true),
				activeUrlHref: decodeURIComponent(!!rawHref ? rawHref : el.href),
				activeUrlDisplayText: el.text,
				activeDomId: el.id,
				linkDialogOpen: true
			});
		}
	}

	onOpenLinkDialog = () => {
		this.setState({linkDialogOpen: true, activeUrlHref: null, activeUrlDisplayText: null});
	}

	onCloseLinkDialog = () => {
		this.setState({linkDialogOpen: false, activeUrlHref: null, activeUrlDisplayText: null});
	}

	onInsertLink = (href, displayText) => {
		let style = "color: inherit !important";

		this.setState({
			linkDialogOpen: false,
			activeUrlHref: null,
			activeUrlDisplayText: null,
		}, () => this.onInsertHtml(`<a id="${GENERATE_DOM_ID()}" href="${href}" target="_blank" rel="noopener noreferrer" style="${style}">${displayText}</a>`));
	}

	onEditLink = (href, displayText) => {
		const activeDomId = this.state.activeDomId;

		this.setState({
			linkDialogOpen: false,
			activeUrlHref: null,
			activeUrlDisplayText: null,
			activeDomId: null,
		}, () => this.onUpdateHtml(activeDomId, {href, text: displayText}));
	}

	onInsertHtml = (html) => {
		const currentHtml = EXTRACT_HTML(this.state) + html;
		this.onChangeHtml(null, currentHtml);
	}

	onUpdateHtml = (id, props) => {
		const activeContentEditable = this.state.activeContentEditable;
		const targetChild = Array.from(activeContentEditable.children).find((a) => a.id === id);

		if (!targetChild) {
			return;
		}
		for (const key of Object.keys(props)) {
			if (!props[key]) {
				continue;
			}
			targetChild[key] = props[key];
		}

		const currentHtml = activeContentEditable.innerHTML;
		this.onChangeHtml(null, currentHtml);
	}

	onChangeHtml = (e, html) => {
		if (typeof html !== 'string') { // !html is true for empty fields
			return;
		} else if (!!e && e.type !== "input") {
			// if there is a block level element (e.g. hr) on the contentEditable, we suddenly start receiving the onKeyDown/onKeyUp events too, so filter on input type
			return;
		}

		if (html.length > MAX_TEXT_LENGTH) {
			html = html.substring(0, MAX_TEXT_LENGTH);
		}

		if (EXTRACT_HTML(this.state) !== html) {
			this.setState({
				customElementsPerType: {
					...this.state.customElementsPerType,
					[this.state.currentCustomElementType]: {
						...this.state.customElementsPerType[this.state.currentCustomElementType],
						[this.state.currentLanguage]: html
					}
				}
			});
		}
	}

	onSave = () => {
		const customElementsPerType = this.state.customElementsPerType;

		// transform from the nested object back to list
		const customElements = [];

		Object.keys(customElementsPerType).forEach(type => Object.keys(customElementsPerType[type]).forEach(language => {
			if (!!customElementsPerType[type][language]) {
				customElements.push({
					type,
					language,
					html: customElementsPerType[type][language]
				})
			}
		}));

		this.props.onSave(customElements);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(CompanyIdpFlowCustomElementsEditorDialog);
