import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Grid, Link, Typography} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";

class InfoVersionComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (!this.props.platformInfo) {
			this.props.onPlatformFetchInfo();
		}
	}

	render() {
		if (!this.props.platformInfo) {
			return <LoadingComponent/>;
		}

		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.platformHeader')}</Typography>
				<Box>{this.props.platformInfo.version}</Box>
			</Grid>
		</Grid>
	}

	renderWithLink(key) {
		return this.props.t(key).split('$').map((v, index) => {
			if (v.indexOf('link') === 0) {
				const elements = v.split(';');
				return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
			} else
				return <span key={index}>{v}</span>
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			platformInfo: state.platform.info,
		}
	},
	dispatch => {
		return {
			onPlatformFetchInfo: () => {
				dispatch({
					type: 'PLATFORM_FETCH_INFO'
				})
			}
		}
	}
)(InfoVersionComponent));
