const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// overview
	overviewInfo: null,
	overviewList: [],
	overviewCount: 0,
	
	// specific visit
	info: null,
	deleted: false,

	// remote-id
	remoteIdSessionCreated: null,

	// doc-id
	documentIdMedia: null,
	documentIdReviewed: false,
};

const visit = (state = defaultState, action) => {
	switch (action.type) {
		case 'VISIT_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'VISIT_SUCCESS':
			return {
				...state,
				busy: false,
			}

		case 'VISIT_FETCH_OVERVIEW_INFO':
		case 'VISIT_FETCH_OVERVIEW_LIST':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'VISIT_FETCH_OVERVIEW_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				overviewInfo: action.data
			}

		case 'VISIT_FETCH_OVERVIEW_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				overviewList: action.list,
				overviewCount: action.count
			}

		case 'VISIT_FETCH_INFO':
			return {
				...state,
				busy: true,
				serverError: null,
				info: null
			}
		case 'VISIT_FETCH_INFO_POLLING':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'VISIT_FETCH_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data
			}

		case 'VISIT_DELETE':
			return {
				...state,
				busy: true,
				serverError: null,
				deleted: false
			}

		case 'VISIT_DELETE_SUCCESS':
			return {
				...state,
				busy: false,
				deleted: true
			}

		case 'VISIT_REMOTE_ID_SESSION_CREATE':
			return {
				...state,
				busy: true,
				serverError: null,
				remoteIdSessionCreated: null
			}

		case 'VISIT_REMOTE_ID_SESSION_CREATE_SUCCESS':
			return {
				...state,
				busy: false,
				remoteIdSessionCreated: action.data
			}

		case 'VISIT_REMOTE_ID_SESSION_RESEND_SMS':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'VISIT_DOCUMENT_ID_FETCH_MEDIA':
			return {
				...state,
				busy: true,
				serverError: null,
				documentIdMedia: null
			}
		case 'VISIT_DOCUMENT_ID_FETCH_MEDIA_SUCCESS':
			if (!!state.documentIdMedia) {
				URL.revokeObjectURL(state.documentIdMedia)
			}
			return {
				...state,
				busy: false,
				documentIdMedia: URL.createObjectURL(action.data)
			}

		case 'VISIT_DOCUMENT_ID_MANUAL_REVIEW':
			return {
				...state,
				busy: true,
				serverError: null,
				documentIdReviewed: false
			}
		case 'VISIT_DOCUMENT_ID_MANUAL_REVIEW_SUCCESS':
			return {
				...state,
				busy: false,
				documentIdReviewed: true,
				info: action.data
			}

		default:
			return state;
	}
}

export default visit;
