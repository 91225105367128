import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SelectAccountDialog from "../common/SelectAccountDialog";

class FolderPermissionsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			accountsToAddDialogOpen: false,
			accountsToAddWithAllPermissions: false,
			accountFilterValue: '',
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onFolderFetchRelevantAccounts();
			this.props.onFolderFetchPermissions(this.props.folderId);
		}
	}

	render() {
		const folderRelevantAccounts = this.props.folderRelevantAccounts;
		const folderPermissions = this.props.folderPermissions;
		const haveAllInfo = !!folderRelevantAccounts && !!folderPermissions;

		const columns = [
			{
				field: 'email',
				headerName: this.props.t('folder.permissionsAccount'),
				editable: false,
				flex: 1
			},
			{
				field: 'editFolder',
				headerName: this.props.t('folder.permissionsEditFolderPermission'),
				editable: false,
				width: 120,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('EDIT_FOLDER') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeAccount(cellValues.row.id, 'EDIT_FOLDER', e.target.checked)}
					/>
			},
			{
				field: 'remoteIdManage',
				headerName: this.props.t('folder.permissionsRemoteIdManagePermission'),
				editable: false,
				width: 200,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('REMOTE_ID_MANAGE') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeAccount(cellValues.row.id, 'REMOTE_ID_MANAGE', e.target.checked)}
					/>
			},
			{
				field: 'remoteIdCreate',
				headerName: this.props.t('folder.permissionsRemoteIdCreatePermission'),
				editable: false,
				width: 200,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('REMOTE_ID_CREATE') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeAccount(cellValues.row.id, 'REMOTE_ID_CREATE', e.target.checked)}
					/>
			},
			{
				field: 'docIdReview',
				headerName: this.props.t('folder.permissionsDocIdReviewPermission'),
				editable: false,
				width: 140,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('DOC_ID_REVIEW') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeAccount(cellValues.row.id, 'DOC_ID_REVIEW', e.target.checked)}
					/>
			},
			{
				field: 'docIdRead',
				headerName: this.props.t('folder.permissionsDocIdReadPermission'),
				editable: false,
				width: 120,
				align: 'center',
				renderCell: (cellValues) =>
					<Checkbox
						checked={cellValues.row.permissions.indexOf('DOC_ID_READ') >= 0}
						disabled={cellValues.row.permissions.indexOf('DOC_ID_REVIEW') >= 0}
						onChange={(e) => this.props.onFolderPermissionsChangeAccount(cellValues.row.id, 'DOC_ID_READ', e.target.checked)}
					/>
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				width: 100,
				align: 'center',
				renderCell: (cellValues) =>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('folder.permissionsDelete')}
						onClick={() => this.props.onFolderPermissionsDeleteAccount(cellValues.row.id)}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
			},
		];

		return <>
			<Dialog open={this.props.open} onClose={this.onClose} onKeyUp={this.onKeyUp} fullWidth maxWidth="lg">
				<DialogTitle>{this.props.t('folder.permissions')}</DialogTitle>
				{!haveAllInfo && <DialogContent><LoadingComponent/></DialogContent>}
				{haveAllInfo && <DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('folder.permissionsHeader')}</StepLabel>
							<StepContent>
								<Box>
									<DataGrid
										disableColumnSelector
										columns={columns}
										slots={{toolbar: FlexibleToolbar}}
										slotProps={{
											toolbar: {
												filterId: 'input-folder-permission-search-text',
												filterValue: this.state.accountFilterValue,
												onChangeFilterValue: this.onAccountFilterChange,
											}
										}}

										disableColumnFilter
										disableRowSelectionOnClick

										rows={folderPermissions.accountsWithPermissions
											.filter(account => account.email.toUpperCase().indexOf(this.state.accountFilterValue.toUpperCase()) >= 0)}

										pagination
										paginationModel={{page: 0, pageSize: 10}}
										pageSizeOptions={[10]}

										density="compact"
										autoHeight

										sx={{mt: 2}}
									/>
								</Box>
								<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenAccountsToAddDialogWithNoPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-accounts-without-permissions"
									>
										{this.props.t('folder.permissionsAddAccountNoPermissions')}
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onOpenAccountsToAddDialogWithAllPermissions}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-folder-permissions-add-accounts-with-permissions"
									>
										{this.props.t('folder.permissionsAddAccountAllPermissions')}
									</Button>
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('folder.permissionsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.permissionsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}

				<DialogActions>
					<Button onClick={this.onClose}
							id="btn-folder-permission-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained" disabled={!haveAllInfo} onClick={this.onUpdate}
							id="btn-folder-permission-confirm">
						{this.props.t('save')}
					</Button>
				</DialogActions>
			</Dialog>

			<SelectAccountDialog
				open={this.state.accountsToAddDialogOpen}
				onClose={this.onCloseAccountsToAddDialog}
				onSelect={this.onAddAccounts}
				title={this.props.t('folder.permissionsAccountSelect')}
				accounts={folderRelevantAccounts || []}
				multiselect/>
		</>
	}

	onOpenAccountsToAddDialogWithNoPermissions = () => {
		this.setState({accountsToAddDialogOpen: true, accountsToAddWithAllPermissions: false});
	}

	onOpenAccountsToAddDialogWithAllPermissions = () => {
		this.setState({accountsToAddDialogOpen: true, accountsToAddWithAllPermissions: true});
	}

	onCloseAccountsToAddDialog = () => {
		this.setState({accountsToAddDialogOpen: false});
	}

	onAddAccounts = (selectedIds) => {
		const permissions = this.state.accountsToAddWithAllPermissions ?
			['EDIT_FOLDER', 'REMOTE_ID_CREATE', 'REMOTE_ID_MANAGE', 'DOC_ID_READ', 'DOC_ID_REVIEW'] : [];
		const accounts = this.props.folderRelevantAccounts
			.filter(account => selectedIds.indexOf(account.id) >= 0)
			.map(account => ({...account, permissions}));
		this.props.onFolderPermissionsAddAccounts(accounts);
		this.setState({accountsToAddDialogOpen: false});
	}

	onAccountFilterChange = (e) => {
		this.setState({accountFilterValue: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onUpdate = () => {
		this.props.onFolderUpdatePermissions(this.props.folderPermissions);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			folderRelevantAccounts: state.folder.relevantAccounts,
			folderPermissions: state.folder.permissions,
		}
	},
	dispatch => {
		return {
			onFolderFetchRelevantAccounts: () => {
				dispatch({
					type: 'FOLDER_FETCH_RELEVANT_ACCOUNTS',
				});
			},
			onFolderFetchPermissions: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_PERMISSIONS',
					folderId
				});
			},
			onFolderPermissionsChangeAccount: (id, permission, enabled) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_CHANGE_ACCOUNT',
					id,
					permission,
					enabled
				});
			},
			onFolderPermissionsDeleteAccount: (id) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_DELETE_ACCOUNT',
					id,
				});
			},
			onFolderPermissionsAddAccounts: (accounts) => {
				dispatch({
					type: 'FOLDER_PERMISSIONS_ADD_ACCOUNTS',
					accounts
				});
			}
		}
	}
)(FolderPermissionsDialog));
