import React from "react";
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, Box} from "@mui/material";
import PasswordTextField from "../common/PasswordTextField";

export default ({password, onPasswordChange, passwordConfirmation, onPasswordConfirmationChange}) => {

	const {t} = useTranslation();

	return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mb: 1}}>
		<PasswordTextField
			required
			id="password"
			label={t('session.password')}
			fullWidth
			variant="outlined"
			type="password"
			value={password}
			onChange={onPasswordChange}
		/>
		<PasswordTextField
			required
			id="password-confirmation"
			label={t('session.confirmPassword')}
			fullWidth
			variant="outlined"
			type="password"
			value={passwordConfirmation}
			onChange={onPasswordConfirmationChange}
		/>

		<Alert icon={false} severity="info">
			<AlertTitle>{t('session.passwordRequirements')}</AlertTitle>
			<ul>
				<li>{t('session.passwordRequirementLength')}</li>
				<li>{t('session.passwordRequirementInclude')}</li>
				<ul>
					<li>{t('session.passwordRequirementUppercase')}</li>
					<li>{t('session.passwordRequirementLowercase')}</li>
					<li>{t('session.passwordRequirementNumber')}</li>
					<li>{t('session.passwordRequirementSpecial')}</li>
				</ul>
			</ul>
		</Alert>
	</Box>;

}
