import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import AppContainer from "../common/AppContainer";
import SessionProfileGeneralComponent from "./SessionProfileGeneralComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import SessionProfileSecurityComponent from "./SessionProfileSecurityComponent";

const TAB_GENERAL = { key: 'GENERAL', translateText: (props) => props.t('session.profileTab_GENERAL') };
const TAB_SECURITY = { key: 'SECURITY', translateText: (props) => props.t('session.profileTab_SECURITY') }
const TABS = [TAB_GENERAL, TAB_SECURITY]

class SessionProfileComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: TABS.at(0)
		};
	}

	render() {
		return <AppContainer withMenu needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
					{TABS.map(tab => <Tab key={tab.key} value={tab} label={tab.translateText(this.props)}></Tab>)}
				</Tabs>
				<Box sx={{mt: 2}}>
					{this.renderCurrentTab()}
				</Box>
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case TAB_GENERAL:
				return <SessionProfileGeneralComponent/>;
			case TAB_SECURITY:
				return <SessionProfileSecurityComponent />;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({tab});
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(SessionProfileComponent));
