import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Grid, Link, Typography} from "@mui/material";

class InfoGeneralComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h6">{this.props.t('info.contact')}</Typography>
				<Link href="mailto:support.dias@dioss.com">support.dias@dioss.com</Link>
			</Grid>
		</Grid>
	}
}

export default withTranslation()(InfoGeneralComponent);
