import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router"

import admin from "./admin";
import app from "./app";
import company from "./company";
import folder from "./folder";
import platform from "./platform";
import session from "./session";
import snackbar from "./snackbar";
import visit from "./visit";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    admin,
    app,
    company,
    folder,
    platform,
    session,
    snackbar,
    visit
});

export default createRootReducer
