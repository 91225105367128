import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Avatar, Box, Button, Link, TextField, Typography} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import LoadingComponent from "../common/LoadingComponent";
import {Redirect, withRouter} from "react-router";
import SessionContainer from "./SessionContainer";
import {Link as RouterLink} from "react-router-dom";

const defaultState = {
    redirect: false,
    error: null
}

const AlternativeLoginButton = (props) => {
    return <Button color="primary"
                   variant="outlined"
                   sx={{mb: 2, p: 0.7}}
                   fullWidth
                   onClick={props.onClick}
                   disabled={props.disabled}
                   sx={{ display: 'flex', justifyContent: 'flex-start' }}
    >
        <Avatar src={props.logo}
                sx={{width: 24, height: 24, mr: 1}}
                alt={props.logoAlt}
        />
        <Box sx={{flexGrow: 1, textAlign: 'center'}}>{props.title}</Box>
    </Button>
}

class LoginComponent extends Component {

    constructor(props) {
        super(props);

        this.state = defaultState;
        this.props.onSessionClearError();
    }

    componentDidMount() {
        if (!this.props.sessionChecked) {
            this.props.onSessionCheck();
        } else {
            if (this.props.sessionCreated) {
                // session already exists, login page has no use, redirect the user to a useful page
                this.setState({redirect: true});
            } else {
                this.props.onFetchCompanyInfo();
            }
        }

        // check for error message in search params
        const data = this.props?.match?.params?.data;
        if (data && data.startsWith('error=')) {
            // set the error and cleanup the URL
            this.setState({error: data.substring('error='.length)}, () => this.props.history.push('/login'));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.busy || this.state.redirect) return;

        if (this.props.sessionCreated) {
            // session already exists, login page has no use, redirect the user to a useful page
            this.setState({redirect: true});
        } else if (!this.props.sessionLoginInfo) {
            this.props.onFetchCompanyInfo();
        }
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to="/"/>);
        } else if (!this.props.sessionLoginInfo) {
            return <LoadingComponent />;
        }

        const {oidcInstances} = this.props.sessionLoginInfo;
        let error = this.props.serverError || this.state.error;

        return <SessionContainer title={this.props.t('login.header')}>
            <Box component="form" onSubmit={this.onSubmit} noValidate>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        id="email"
                        label={this.props.t('login.emailAddress')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        sx={{flexGrow: 1, mt: 0}}
                    />
                    <TextField
                        margin="normal"
                        required
                        name="password"
                        label={this.props.t('login.password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        sx={{flexGrow: 1, mt: 0}}
                    />
                </Box>
                <Button type="submit" disabled={this.props.loginBusy} fullWidth variant="contained">{this.props.t('login.header')}</Button>

                <RouterLink to="/reset-password-request" style={{textDecoration: 'none'}}>
                    <Link component="button" underline="hover" sx={{mt: 0.5, display:'block'}} variant="body2">{this.props.t('login.forgotPassword')}</Link>
                </RouterLink>
            </Box>


            {error && <ServerErrorComponent serverError={error} />}

            {this.props.busy && <Box sx={{mt: 1}}>
                <LoadingComponent/>
            </Box>}

            {!this.props.busy && oidcInstances?.length > 0 &&
                <Box sx={{mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
                    <Typography variant="body2" sx={{color: 'grey'}}>{this.props.t('session.alternativeLogins')}</Typography>

                    {(oidcInstances || []).map((oidcInstance) =>
                        <AlternativeLoginButton
                            key={oidcInstance.id}
                            onClick={() => this.props.onSelectOidcInstance(oidcInstance.id)}
                            disabled={this.props.busy || this.props.loginBusy}
                            logo={oidcInstance.logoBase64 ? 'data:image/png;base64,' + oidcInstance.logoBase64 : ''}
                            logoAlt={oidcInstance.name}
                            title={oidcInstance.name}
                        />)}
                </Box>
            }
        </SessionContainer>
    }

    onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.props.onSessionLoginPassword({
            email: data.get('email'),
            secret: data.get('password'),
            companyId: this.props.sessionLoginInfo?.companyId
        });
    }

}

export default withRouter(withTranslation()(connect(
    state => {
        return {
            busy: state.session.busy,
            loginBusy: state.session.loginBusy,
            serverError: state.session.serverError,
            sessionLoginInfo: state.session.loginInfo,
            sessionCreated: state.session.created,
            sessionChecked: state.session.checked,
        }
    },
    dispatch => {
        return {
            onSessionCheck: () => {
                dispatch({
                    type: 'SESSION_CHECK',
                    loginPageOrigin: true
                })
            },
            onSessionClearError: () => {
                dispatch({
                    type: 'SESSION_CLEAR_ERROR',
                })
            },
            onSessionLoginPassword: (request) => {
                dispatch({
                    type: 'SESSION_LOGIN_PASSWORD',
                    request
                });
            },
            onFetchCompanyInfo: () => {
                dispatch({
                    type: 'SESSION_FETCH_COMPANY_INFO'
                })
            },
            onSelectOidcInstance: (oidcInstanceId) => {
                dispatch({
                    type: 'SESSION_CREATE_OIDC',
                    oidcInstanceId
                })
            }
        }
    }
)(LoginComponent)));
