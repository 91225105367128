const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// current session info
	info: null,

	// did we check
	checked: false,

	// did we (try to) create a session?
	created: false,

	// profile
	profile: null,

	// login
	email: null,

	// reset password
	resetPasswordRequestSuccess: false,
	resetPasswordUpdateSuccess: false,

	// companies where the account is in
	companyList: []
};

const session = (state = defaultState, action) => {
	switch (action.type) {
		case 'SESSION_CLEAR_ERROR':
			return {
				...state,
				busy: false,
				serverError: null,
				resetPasswordRequestSuccess: false,
				resetPasswordUpdateSuccess: false,
			}
		case 'SESSION_ERROR':
			return {
				...state,
				busy: false,
				loginBusy: false,
				serverError: action.serverError
			}

		case 'SESSION_REFRESH_INFO':
		case 'SESSION_FETCH_PROFILE':
		case 'SESSION_UPDATE_PROFILE':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'SESSION_CHECK':
			return {
				...state,
				busy: true,
				serverError: null,
				checked: false,
				created: false,
			}

		case 'SESSION_CHECK_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
				checked: true,
				created: action.created,
			}

		case 'SESSION_REFRESH_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				info: {
					...state.info,
					...action.data
				}
			}

		case 'SESSION_DESTROY':
			return {
				...state,
				info: null,
				created: false,
				busy: false,
			}

		case 'SESSION_SWITCH_COMPANY':
			return {
				...state,
				busy: true,
				serverError: null,
			}
		case 'SESSION_SWITCH_COMPANY_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
				created: true,
			}

		case 'SESSION_FETCH_PROFILE_SUCCESS':
			return {
				...state,
				busy: false,
				profile: action.data,
			}

		case 'SESSION_UPDATE_PROFILE_SUCCESS':
			return {
				...state,
				busy: false,
				info: {
					...state.info,
					language: action.profile?.language || state.info.language,
					defaultRowCount: action.profile?.defaultRowCount || state.info.defaultRowCount,
				},
			}

		case 'SESSION_CHANGE_PROFILE_DATA': {
			const profile = {
				...state.profile
			};
			profile[action.key] = action.value;
			return {
				...state,
				profile
			}
		}

		case 'SESSION_FETCH_COMPANY_INFO':
			return {
				...state,
				serverError: null,
				busy: true
			}

		case 'SESSION_FETCH_COMPANY_INFO_SUCCESS':
			return {
				...state,
				loginInfo: action.data,
				serverError: null,
				busy: false
			}

		case 'SESSION_LOGIN_PASSWORD':
			return {
				...state,
				loginBusy: true,
				serverError: null
			}

		case 'SESSION_PASSWORD_SET':
			return {
				...state,
				busy: true,
				serverError: null
			}
		case 'SESSION_LOGIN_PASSWORD_ERROR':
			return {
				...state,
				busy: false,
				serverError: null
			}

		case 'SESSION_RESET_PASSWORD_REQUEST':
			return {
				...state,
				busy: true,
				serverError: null,
				resetPasswordRequestSuccess: false
			}
		case 'SESSION_RESET_PASSWORD_REQUEST_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				resetPasswordRequestSuccess: true
			}
		case 'SESSION_RESET_PASSWORD_UPDATE':
			return {
				...state,
				busy: true,
				serverError: null,
				resetPasswordUpdateSuccess: false
			}
		case 'SESSION_RESET_PASSWORD_UPDATE_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				resetPasswordUpdateSuccess: true
			}

		case 'SESSION_REMOVE_ACCOUNT':
			return {
				...state,
				busy: true,
				serverError: null,
			}

		case 'SESSION_FETCH_COMPANY_LIST':
			return {
				...state,
				busy: true,
				serverError: null,
				companyList: [],
			}
		case 'SESSION_FETCH_COMPANY_LIST_SUCCESS':
			return {
				...state,
				busy: false,
				companyList: action.data,
			}

		case 'SESSION_SUCCESS':
			return {
				...state,
				busy: false,
				serverError: null,
				loginBusy: false
			}

		default:
			return state;
	}
}

export default session;
