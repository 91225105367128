import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Alert, Box, Button, TextField} from "@mui/material";
import ResetPasswordContainer from "./ResetPasswordContainer";

class ResetPasswordRequestComponent extends Component {

    constructor(props) {
        super(props);
        this.props.onAccountClearError();
    }

    render() {
        return <ResetPasswordContainer>
            <Box component="form" onSubmit={this.onSubmit} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={this.props.t('login.emailAddress')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Box sx={{mb: 1}}>
                    {this.props.serverError &&
                        <Alert severity="error">{this.props.t([`serverError.${this.props.serverError}`, 'serverError.GENERAL'])}</Alert>}
                    {this.props.resetPasswordRequestSuccess &&
                        <Alert severity="success">{this.props.t('session.resetPasswordSuccess')}</Alert>}
                </Box>
                {!this.props.resetPasswordRequestSuccess &&
                    <Button type="submit" disabled={this.props.busy} fullWidth variant="contained">{this.props.t('next')}</Button>}
            </Box>
        </ResetPasswordContainer>
    }

    onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.props.onAccountResetPassword(data.get('email'));
    }

}

export default withTranslation()(connect(
    state => {
        return {
            busy: state.session.busy,
            serverError: state.session.serverError,
            resetPasswordRequestSuccess: state.session.resetPasswordRequestSuccess,
        }
    },
    dispatch => {
        return {
            onAccountClearError: () => {
                dispatch({
                    type: 'SESSION_CLEAR_ERROR',
                })
            },
            onAccountResetPassword: (email) => {
                dispatch({
                    type: 'SESSION_RESET_PASSWORD_REQUEST',
                    email
                });
            }
        }
    }
)(ResetPasswordRequestComponent));
