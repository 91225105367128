import {Component} from "react";
import {connect} from "react-redux";
import {Alert, Snackbar} from "@mui/material";
import {withTranslation} from "react-i18next";

class GlobalSnackbar extends Component {

	render() {
		let message = this.props.snackbarMessageKey ? this.props.t(this.props.snackbarMessageKey) : this.props.snackbarMessage;
		if (!!this.props.snackBarMessageArgumentsMap) {
			for (const [key, value] of this.props.snackBarMessageArgumentsMap) {
				message = message.replace(key, value);
			}
		}

		return <Snackbar open={this.props.snackbarOpen}
						 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
						 autoHideDuration={3000}
						 onClose={this.props.onSnackbarClose}>
			<Alert severity="success" sx={{width: '100%'}}>
				{message}
			</Alert>
		</Snackbar>
	}

}

export default withTranslation()(connect(
	state => {
		return {
			snackbarOpen: state.snackbar.open,
			snackbarMessage: state.snackbar.message,
			snackbarMessageKey: state.snackbar.messageKey,
			snackBarMessageArgumentsMap: state.snackbar.messageArgumentsMap,
		}
	},
	dispatch => {
		return {
			onSnackbarClose: () => {
				dispatch({
					type: 'SNACKBAR_CLOSE'
				})
			}
		}
	}
)(GlobalSnackbar))
