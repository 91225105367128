import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";

const defaultState = {};

class FolderSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onFolderFetchSettings(this.props.folderId);
		}
	}

	render() {
		const settings = this.props.folderSettings;
		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					onKeyUp={this.onKeyUp}
					fullWidth
					maxWidth="md">
				<DialogTitle>{this.props.t('folder.settings')}</DialogTitle>
				{!settings && <DialogContent><LoadingComponent/></DialogContent>}
				{settings && <DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('folder.nameHeader')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('folder.name')}
									disabled={settings.defaultFolder}
									required
									value={settings.name}
									onChange={this.onChangeName}
									autoComplete="off"
									fullWidth
									autoFocus
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('folder.descriptionHeader')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('folder.description')}
									required
									value={settings.description || ''}
									onChange={this.onChangeDescription}
									autoComplete="off"
									fullWidth
									autoFocus
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('folder.ivoEmailHeader')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									label={this.props.t('folder.ivoEmail')}
									value={settings.ivoEmail || ''}
									onChange={this.onChangeIvoEmail}
									autoComplete="off"
									fullWidth
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('folder.cleanupInDaysHeader')}</StepLabel>
							<StepContent>
								<TextField
									type="number"
									variant="outlined"
									label={this.props.t('folder.cleanupInDays')}
									value={settings.cleanupInDays || ''}
									onChange={this.onChangeCleanupInDays}
									autoComplete="off"
									fullWidth
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('folder.manualReviewCleanupInDaysHeader')}</StepLabel>
							<StepContent>
								<TextField
									type="number"
									variant="outlined"
									label={this.props.t('folder.manualReviewCleanupInDays')}
									value={settings.manualReviewCleanupInDays || ''}
									onChange={this.onChangeManualReviewCleanupInDays}
									autoComplete="off"
									fullWidth
								/>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel>{this.props.t('folder.settingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.settingsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained" disabled={!this.canContinue()}
							onClick={this.onUpdate}
							id="btn-settings-save">
						{this.props.t('save')}
					</Button>
				</DialogActions>
			</Dialog>

		</Fragment>
	}

	onChangeName = (e) => {
		this.props.onFolderChangeSetting('name', null, e.target.value);
	}

	onChangeDescription = (e) => {
		this.props.onFolderChangeSetting('description', null, e.target.value);
	}

	onChangeIvoEmail = (e) => {
		this.props.onFolderChangeSetting('ivoEmail', null, e.target.value);
	}

	onChangeCleanupInDays = (e) => {
		this.props.onFolderChangeSetting('cleanupInDays', null, (e.target.value === '' ? null : parseInt(e.target.value)));
	}

	onChangeManualReviewCleanupInDays = (e) => {
		this.props.onFolderChangeSetting('manualReviewCleanupInDays', null, (e.target.value === '' ? null : parseInt(e.target.value)));
	}

	canContinue = () => {
		return !!this.props.folderSettings?.name?.trim();
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onUpdate = () => {
		this.props.onFolderUpdateSettings(this.props.folderSettings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			folderSettings: state.folder.settings,
		}
	},
	dispatch => {
		return {
			onFolderFetchSettings: (folderId) => {
				dispatch({
					type: 'FOLDER_FETCH_SETTINGS',
					folderId
				});
			},
			onFolderChangeSetting: (key, subkey, value) => {
				dispatch({
					type: 'FOLDER_CHANGE_SETTING',
					key,
					subkey,
					value
				});
			}
		}
	}
)(FolderSettingsDialog));
