const defaultState = {
	// general state
	busy: false,
	serverError: false,

	// current platform info
	info: null,
};

const platform = (state = defaultState, action) => {
	switch (action.type) {
		case 'PLATFORM_ERROR':
			return {
				...state,
				busy: false,
				serverError: action.serverError
			}

		case 'PLATFORM_FETCH_INFO':
			return {
				...state,
				busy: true,
				serverError: null
			}

		case 'PLATFORM_FETCH_INFO_SUCCESS':
			return {
				...state,
				busy: false,
				info: action.data,
			}

		default:
			return state;
	}
}

export default platform;
