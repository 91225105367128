import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Box} from "@mui/material";
import ServerErrorComponent from "../common/ServerErrorComponent";
import CompanyIdpFlowOverviewComponent from "./CompanyIdpFlowOverviewComponent";
import CompanyIdpClientSecretExpiryKeyOverviewComponent from "./CompanyIdpClientSecretExpiryKeyOverviewComponent";

class CompanyIdpComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Box sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<CompanyIdpClientSecretExpiryKeyOverviewComponent/>
			<CompanyIdpFlowOverviewComponent/>
		</Box>
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyServerError: state.company.serverError,
		}
	},
	dispatch => {
		return {}
	}
)(CompanyIdpComponent));
