import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "./FlexibleToolbar";
import {styled} from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
	"& .MuiDataGrid-columnHeaders": {display: "none"},
	"& .MuiDataGrid-virtualScroller": {marginTop: "0!important"},
}));

const defaultState = {
	filterValue: '',
	filterValueUC: '',
	selectedIds: [],
};

class SelectAccountDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		const columns = [
			{
				field: 'email',
				editable: false,
				flex: 1
			}
		];

		return <Dialog open={this.props.open}
					   onClose={this.props.onClose}
					   disableRestoreFocus
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md">
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<StyledDataGrid
					disableColumnSelector
					disableColumnFilter
					checkboxSelection
					keepNonExistentRowsSelected

					hideFooterSelectedRowCount={!this.props.multiselect}
					rowSelectionModel={this.state.selectedIds}
					onRowSelectionModelChange={this.onChangeSelectedValues}
					columns={columns}
					slots={{toolbar: FlexibleToolbar}}
					slotProps={{
						baseCheckbox: {
							tabIndex: 0
						},
						toolbar: {
							filterId: 'input-select-account-search-text',
							filterValue: this.state.filterValue,
							onChangeFilterValue: this.onFilterValueChange,
						}
					}}

					rows={(this.props.accounts || []).filter(account =>
						account.email.toUpperCase().indexOf(this.state.filterValueUC) >= 0)}
					autoPageSize
					density="compact"
					sx={{height: 500}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.props.onClose} id="btn-selection-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" onClick={this.onSelect} id="btn-selection-confirm"
						disabled={this.state.selectedIds.length === 0}>{this.props.t('select')}</Button>
			</DialogActions>
		</Dialog>
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.state.selectedIds.length !== 0) {
			this.onSelect();
		}
	}

	onChangeSelectedValues = (selectedIds) => {
		if (this.props.multiselect) {
			this.setState({selectedIds});
		} else {
			const currentSelection = this.state.selectedIds[0];
			this.setState({selectedIds: selectedIds.filter(el => !currentSelection || el !== currentSelection)});
		}
	}

	onFilterValueChange = (e) => {
		const filterValue = e.target.value;
		this.setState({filterValue, filterValueUC: filterValue.toUpperCase()});
	}

	onSelect = () => {
		this.props.onSelect(this.state.selectedIds);
	}

}

export default withTranslation()(SelectAccountDialog);
