import axios from "axios";
import {push} from "connected-react-router";
import {all, delay, put, select, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import i18n from "i18next";
import {saveAs} from "file-saver";

export default function* rootSaga() {
	yield all([
		// session
		takeEvery('SESSION_CHECK', sessionCheck),
		takeEvery('SESSION_FETCH_COMPANY_INFO', sessionFetchCompanyInfo),
		takeLeading('SESSION_LOGIN_PASSWORD', sessionLoginPassword),
		takeEvery('SESSION_CREATE_OIDC', sessionCreateOidc),
		takeEvery('SESSION_OIDC_LOGIN_CALLBACK', sessionOidcLoginCallback),
		takeEvery('SESSION_REFRESH_INFO', sessionRefreshInfo),
		takeEvery('SESSION_SWITCH_COMPANY', sessionSwitchCompany),
		takeEvery('SESSION_FETCH_PROFILE', sessionFetchProfile),
		takeEvery('SESSION_UPDATE_PROFILE', sessionUpdateProfile),
		takeEvery('SESSION_RESET_PASSWORD_REQUEST', sessionResetPasswordRequest),
		takeEvery('SESSION_RESET_PASSWORD_UPDATE', sessionResetPasswordUpdate),
		takeEvery('SESSION_RESET_PASSWORD_FINISH', sessionResetPasswordFinish),
		takeEvery('SESSION_PASSWORD_SET', sessionPasswordSet),
		takeEvery('SESSION_LOGOUT', sessionLogout),
		takeEvery('SESSION_REMOVE_ACCOUNT', sessionRemoveAccount),
		takeEvery('SESSION_ACCEPT_TERMS', sessionAcceptTerms),
		takeEvery('SESSION_FETCH_COMPANY_LIST', sessionFetchCompanyList),
		// company
		takeEvery('COMPANY_FETCH_OVERVIEW_LIST', companyFetchOverviewList),
		takeEvery('COMPANY_CREATE', companyCreate),
		takeEvery('COMPANY_DELETE', companyDelete),
		takeEvery('COMPANY_FETCH_ADMIN_SETTINGS', companyFetchAdminSettings),
		takeEvery('COMPANY_UPDATE_ADMIN_SETTINGS', companyUpdateAdminSettings),
		takeEvery('COMPANY_ACCOUNT_FETCH_OVERVIEW_LIST', companyAccountFetchOverviewList),
		takeEvery('COMPANY_ACCOUNT_INVITE', companyAccountInvite),
		takeEvery('COMPANY_ACCOUNT_RESEND_INVITATION', companyAccountResendInvitation),
		takeEvery('COMPANY_ACCOUNT_FETCH_SETTINGS', companyAccountFetchSettings),
		takeEvery('COMPANY_ACCOUNT_UPDATE_SETTINGS', companyAccountUpdateSettings),
		takeEvery('COMPANY_ACCOUNT_DELETE', companyAccountDelete),
		takeLatest('COMPANY_APIKEY_FETCH', companyApiKeysFetch),
		takeEvery('COMPANY_APIKEY_CREATE', companyApiKeyCreate),
		takeEvery('COMPANY_APIKEY_UPDATE', companyApiKeyUpdate),
		takeEvery('COMPANY_APIKEY_REVOKE', companyApiKeyRevoke),
		takeLatest('COMPANY_IDP_CLIENT_SECRET_FETCH', companyIdpClientSecretFetch),
		takeEvery('COMPANY_IDP_CLIENT_SECRET_CREATE', companyIdpClientSecretCreate),
		takeEvery('COMPANY_IDP_CLIENT_SECRET_UPDATE', companyIdpClientSecretUpdate),
		takeEvery('COMPANY_IDP_CLIENT_SECRET_REVOKE', companyIdpClientSecretRevoke),
		takeEvery('COMPANY_FETCH_STATS_CONFIG', companyFetchStatsConfig),
		takeEvery('COMPANY_FETCH_STATS', companyFetchStats),
		takeEvery('COMPANY_EXPORT_STATS', companyExportStats),
		takeEvery('COMPANY_EXPORT_STATS_DETAILS', companyExportStatsDetails),
		takeEvery('COMPANY_FETCH_ACTION_LOGS', companyFetchActionLogs),
		takeEvery('COMPANY_IDP_FLOW_FETCH_EDIT_INFO', companyIdpFlowFetchEditInfo),
		takeEvery('COMPANY_IDP_FLOW_FETCH_LIST', companyIdpFlowFetchList),
		takeEvery('COMPANY_IDP_FLOW_CREATE', companyIdpFlowCreate),
		takeEvery('COMPANY_IDP_FLOW_FETCH_SETTINGS', companyIdpFlowFetchSettings),
		takeEvery('COMPANY_IDP_FLOW_UPDATE_SETTINGS', companyIdpFlowUpdateSettings),
		takeEvery('COMPANY_IDP_FLOW_DELETE', companyIdpFlowDelete),
		takeEvery('COMPANY_FETCH_STYLE_SETTINGS', companyFetchStyleSettings),
		takeEvery('COMPANY_UPDATE_STYLE_SETTINGS', companyUpdateStyleSettings),
		takeEvery('COMPANY_OIDC_INSTANCE_FETCH_LIST', companyFetchOidcInstanceList),
		takeEvery('COMPANY_OIDC_INSTANCE_FETCH_SETTINGS', companyFetchOidcInstanceSettings),
		takeEvery('COMPANY_OIDC_INSTANCE_CREATE', companyCreateOidcInstance),
		takeEvery('COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS', companyUpdateOidcInstanceSettings),
		takeEvery('COMPANY_OIDC_INSTANCE_UPDATE_LOGO', companyUpdateOidcInstanceLogo),
		takeEvery('COMPANY_OIDC_INSTANCE_DELETE', companyDeleteOidcInstance),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_FETCH_OVERVIEW_LIST', companySandboxAccountFetchOverviewList),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_CREATE', companySandboxAccountCreate),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_IMPORT', companySandboxAccountImport),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_FETCH', companySandboxAccountFetch),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_UPDATE', companySandboxAccountUpdate),
		takeEvery('COMPANY_SANDBOX_ACCOUNT_DELETE', companySandboxAccountDelete),
		// folder
		takeEvery('FOLDER_FETCH_OVERVIEW_LIST', folderFetchOverviewList),
		takeEvery('FOLDER_CREATE', folderCreate),
		takeEvery('FOLDER_FETCH_SETTINGS', folderFetchSettings),
		takeEvery('FOLDER_UPDATE_SETTINGS', folderUpdateSettings),
		takeEvery('FOLDER_DELETE', folderDelete),
		takeEvery('FOLDER_FETCH_RELEVANT_ACCOUNTS', folderFetchRelevantAccounts),
		takeEvery('FOLDER_FETCH_PERMISSIONS', folderFetchPermissions),
		takeEvery('FOLDER_UPDATE_PERMISSIONS', folderUpdatePermissions),
		// platform
		takeEvery('PLATFORM_FETCH_INFO', platformFetchInfo),
		// visit
		takeEvery('VISIT_FETCH_OVERVIEW_INFO', visitFetchOverviewInfo),
		takeEvery('VISIT_FETCH_OVERVIEW_LIST', visitFetchOverviewList),
		takeEvery('VISIT_FETCH_INFO', visitFetchInfo),
		takeEvery('VISIT_FETCH_INFO_POLLING', visitFetchInfo),
		takeEvery('VISIT_DELETE', visitDelete),
		takeEvery('VISIT_REMOTE_ID_SESSION_CREATE', visitRemoteIdSessionCreate),
		takeEvery('VISIT_REMOTE_ID_SESSION_RESEND_SMS', visitRemoteIdSessionResendSms),
		takeEvery('VISIT_DOCUMENT_ID_FETCH_MEDIA', visitDocumentIdFetchMedia),
		takeEvery('VISIT_DOCUMENT_ID_MANUAL_REVIEW', visitDocumentIdManualReview),
	]);
}

/*** CONSTANTS ********************************************************************************************************/

const MAX_FILE_SIZE = 1048576; // 1MB; base64 converted files larger > 7Mb produce network issues

/*** MISC/HELPERS *****************************************************************************************************/

function* axiosRequest(url, method, headers, data, responseType, progress) {
	try {
		const config = {
			url: url,
			method: method,
			headers: headers ?? {}
		};

		config.headers['X-Requested-With'] = 'XMLHttpRequest';

		if (!!data) {
			config.data = data;
		}
		if (!!responseType) {
			config.responseType = responseType;
		}
		if (!!progress) {
			config.onUploadProgress = progress;
		}

		const accountCsrfToken = localStorage.getItem('TOKEN_ACCOUNT_CSRF');
		if (!!accountCsrfToken) {
			config.headers['X-Csrf-Token-Account'] = accountCsrfToken;
		}

		return yield axios(config);
	} catch (e) {
		if (401 === e.response.status) {
			yield put({
				type: 'SESSION_DESTROY'
			});
		} else {
			throw e;
		}
	}
}

function* axiosPost(url, data, progress, responseType) {
	return yield axiosRequest(url, 'post', null, data, responseType, progress);
}

function* axiosPut(url, data, progress) {
	return yield axiosRequest(url, 'put', null, data, null, progress);
}

function* axiosGet(url, responseType) {
	return yield axiosRequest(url, 'get', null, null, responseType);
}

function* axiosDelete(url, data) {
	return yield axiosRequest(url, 'delete', null, data);
}

function* extractServerError(e) {
	if (!!e.response && !!e.response.status && 412 === e.response.status && !!e.response.data) {
		if (e.response.data instanceof Blob) {
			const data = JSON.parse(yield e.response.data.text());
			if (!!data.errorCode) {
				return data.errorCode;
			}
		} else if (!!e.response.data.errorCode) {
			return e.response.data.errorCode;
		}
	}

	return 'GENERAL';
}

/*** SESSION **********************************************************************************************************/

function* sessionFetchCompanyInfo(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/company-info', {currentHostName: window.location.hostname});

		yield put({
			type: 'SESSION_FETCH_COMPANY_INFO_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionLoginPassword(action) {
	try {
		const {data: {result, tokens}} = yield axiosPost('/api/internal/session/login-password', action.request);

		if (!!tokens.ACCOUNT_CSRF) {
			// keep CSRF token
			localStorage.setItem('TOKEN_ACCOUNT_CSRF', tokens.ACCOUNT_CSRF.value);
		}

		if ('SUCCESS' === result) {
			yield put({
				type: 'SESSION_SUCCESS'
			});
			const postLoginPath = localStorage.getItem('post-login-path');
			yield put(push(postLoginPath || '/'));
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionCreateOidc(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/login-oidc-start', {
			oidcInstanceId: action.oidcInstanceId
		});
		if (data.oidcServerDown) {
			yield delay(5000);
		} else {
			// if there is no specific path set, set one now to redirect the user to after successful authentication
			let postLoginPath = localStorage.getItem('post-login-path');
			if (!postLoginPath) localStorage.setItem('post-login-path', '/');

			window.location.assign(data.redirectUrl);
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionOidcLoginCallback(action) {
	try {
		const {data: {tokens}} = yield axiosPost('/api/internal/session/login-oidc-complete', {
			oidcCallbackUrl: window.location.href,
			languageIsoCode: i18n.resolvedLanguage
		});
		if (!!tokens.ACCOUNT_CSRF) {
			// keep CSRF token
			localStorage.setItem('TOKEN_ACCOUNT_CSRF', tokens.ACCOUNT_CSRF.value);
		}

		window.location.assign(window.location.origin + '/#' + (action.loginSavedPath || '/'));
	} catch (e) {
		const serverError = yield extractServerError(e);
		window.location.assign(window.location.origin + '/#/login/error=' + serverError);
	}
}

function* sessionCheck(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/info', {currentUrl: window.location.origin, fullInfo: true});
		if (!!data?.companyUrl) {
			// redirect the user to the correct URL if the domain doesn't match
			let redirectUrl = data.companyUrl + '/' + window.location.hash;

			const csrfToken = localStorage.getItem('TOKEN_ACCOUNT_CSRF');
			if (!!csrfToken) {
				localStorage.removeItem('TOKEN_ACCOUNT_CSRF');

				redirectUrl += '?csrf=' + csrfToken;
			}

			window.location.assign(redirectUrl);
			return;
		}

		if (!!data) {
			if (!!data.language && data.language.toLowerCase() !== i18n.resolvedLanguage) {
				yield i18n.changeLanguage(data.language.toLowerCase());
			}
			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: true,
				data
			});
			return;
		}

		// no session exists yet -> either redirect to the login page, or inform the login page the session did not exist
		const {router: {location: {pathname}}} = yield select();
		if (!action.loginPageOrigin) {
			localStorage.setItem('post-login-path', pathname);
			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: false
			});
			yield put(push('/login'));
		} else {
			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: false
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionRefreshInfo(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/info', {fullInfo: !!action.fullInfo});
		if (!!data) {
			yield put({
				type: 'SESSION_REFRESH_INFO_SUCCESS',
				data
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionSwitchCompany(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/switch-to-company', action.request);
		if (!!data.companyUrl && window.location.origin !== data.companyUrl) {
			let redirectUrl = data.companyUrl + '/' + window.location.hash;

			const csrfToken = localStorage.getItem('TOKEN_ACCOUNT_CSRF');
			if (!!csrfToken) {
				localStorage.removeItem('TOKEN_ACCOUNT_CSRF');

				redirectUrl += '?csrf=' + csrfToken;
			}

			window.location.assign(redirectUrl);
		} else {
			yield put({
				type: 'SESSION_SWITCH_COMPANY_SUCCESS',
				data
			});
		}

	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionFetchProfile(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/profile');
		if (!!data) {
			yield put({
				type: 'SESSION_FETCH_PROFILE_SUCCESS',
				data
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateProfile(action) {
	try {
		yield axiosPut('/api/internal/session/profile', action.request);
		yield put({
			type: 'SESSION_UPDATE_PROFILE_SUCCESS',
			profile: action.request
		});
		if (!!action.request?.language) {
			const lang = action.request.language.toLowerCase();
			if (lang !== i18n.resolvedLanguage) {
				yield i18n.changeLanguage(lang);
			}
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionResetPasswordRequest(action) {
	try {
		yield axiosPost('/api/internal/session/reset-password', {
			email: action.email,
		});

		yield put({
			type: 'SESSION_RESET_PASSWORD_REQUEST_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionResetPasswordUpdate(action) {
	try {
		yield axiosPost('/api/internal/session/update-password', {
			payload: action.payload,
			password: action.password,
			passwordConfirmation: action.passwordConfirmation
		});

		yield put({
			type: 'SESSION_RESET_PASSWORD_UPDATE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}


function* sessionPasswordSet(action) {
	try {
		yield axiosPost('/api/internal/session/password-set', {
			password: action.password,
			passwordConfirmation: action.passwordConfirmation
		});

		yield put({
			type: 'SESSION_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('session.passwordIsSet')
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionResetPasswordFinish(action) {
	yield put(push('/login'));
}

function* sessionLogout(action) {
	try {
		const {data: {redirectUrl}} = yield axiosPost('/api/internal/session/logout', {});

		localStorage.removeItem('TOKEN_ACCOUNT_CSRF');

		if (!!redirectUrl) {
			window.location.assign(redirectUrl);
		} else {
			yield put(push('/login'));
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionRemoveAccount(action) {
	try {
		yield axiosPost('/api/internal/session/remove-account', {});
		yield put(push('/login'));
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionAcceptTerms(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/accept-terms', {});
		yield put({
			type: 'SESSION_REFRESH_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionFetchCompanyList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/company-list');
		yield put({
			type: 'SESSION_FETCH_COMPANY_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** COMPANY **********************************************************************************************************/

function* companyFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/company/overview', action.request);
		yield put({
			type: 'COMPANY_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreate(action) {
	try {
		yield axiosPost('/api/internal/company/', action.request);
		yield put({
			type: 'COMPANY_CREATE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDelete(action) {
	try {
		yield axiosDelete(`/api/internal/company/${action.companyId}`);
		yield put({
			type: 'COMPANY_DELETE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchAdminSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/${action.companyId}/settings/admin`);
		yield put({
			type: 'COMPANY_FETCH_ADMIN_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateAdminSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/${action.companyId}/settings/admin`, action.settings);
		yield put({
			type: 'COMPANY_UPDATE_ADMIN_SETTINGS_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/company/current/account-overview', action.request);
		yield put({
			type: 'COMPANY_ACCOUNT_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountInvite(action) {
	try {
		const {data} = yield axiosPost('/api/internal/company/current/account/invite', action.request);
		yield put({
			type: 'COMPANY_ACCOUNT_INVITE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountResendInvitation(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/account/${action.accountId}/resend-invitation`);
		yield put({
			type: 'COMPANY_SUCCESS',
			data
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('company.accountInvitationSent'),
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/account/${action.accountId}/settings`);
		yield put({
			type: 'COMPANY_ACCOUNT_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/current/account/${action.accountId}/settings`, action.settings);
		yield put({
			type: 'COMPANY_ACCOUNT_UPDATE_SETTINGS_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyAccountDelete(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/account/${action.accountId}`);
		yield put({
			type: 'COMPANY_ACCOUNT_DELETE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeysFetch(action) {
	try {
		const {
			data: {
				list,
				count
			}
		} = yield axiosPost('/api/internal/company/current/settings/apikey-overview', action.request);
		yield put({
			type: 'COMPANY_APIKEY_FETCH_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyCreate(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/apikey`, action.request);
		yield put({
			type: 'COMPANY_APIKEY_CREATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyUpdate(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/apikey/${action.companyApiKeyId}`, action.request);
		yield put({
			type: 'COMPANY_APIKEY_UPDATE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyRevoke(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/apikey/${action.companyApiKeyId}`);
		yield put({
			type: 'COMPANY_APIKEY_REVOKE_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpClientSecretFetch(action) {
	try {
		const {
			data: {
				list,
				count
			}
		} = yield axiosPost('/api/internal/company/current/settings/idp-secret-overview', action.request);
		yield put({
			type: 'COMPANY_IDP_CLIENT_SECRET_FETCH_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpClientSecretCreate(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/idp-secret`, action.request);
		yield put({
			type: 'COMPANY_IDP_CLIENT_SECRET_CREATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpClientSecretUpdate(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/idp-secret/${action.clientSecretId}`, action.request);
		yield put({
			type: 'COMPANY_IDP_CLIENT_SECRET_UPDATE_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpClientSecretRevoke(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/idp-secret/${action.clientSecretId}`);
		yield put({
			type: 'COMPANY_IDP_CLIENT_SECRET_REVOKE_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStatsConfig(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/stats-config');
		yield put({
			type: 'COMPANY_FETCH_STATS_CONFIG_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStats(action) {
	try {
		const {data} = yield axiosPost('/api/internal/company/current/stats', action.request);
		yield put({
			type: 'COMPANY_FETCH_STATS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyExportStats(action) {
	try {
		const result = yield axiosPost('/api/internal/company/current/stats-export', action.request, undefined, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'statistics-export.csv';
		saveAs(result.data, fileName);

		yield put({type: 'COMPANY_SUCCESS'});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyExportStatsDetails(action) {
	try {
		const result = yield axiosPost('/api/internal/company/current/stats-details-export', action.request, undefined, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'statistics-export.csv';
		saveAs(result.data, fileName);

		yield put({type: 'COMPANY_SUCCESS'});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchActionLogs(action) {
	try {
		const {data: {list, count}} = yield axiosPost(`/api/internal/company/current/action-logs`, action.request);
		yield put({
			type: 'COMPANY_FETCH_ACTION_LOGS_SUCCESS',
			list,
			count
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowFetchEditInfo() {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/idp-flow/edit-info');
		yield put({
			type: 'COMPANY_IDP_FLOW_FETCH_EDIT_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowFetchList() {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/idp-flow/overview');
		yield put({
			type: 'COMPANY_IDP_FLOW_FETCH_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowCreate(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/company/current/settings/idp-flow', action.request);
		yield put({
			type: 'COMPANY_IDP_FLOW_CREATE_SUCCESS',
			createdFlowId: id
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/idp-flow/${action.companyFlowId}`);
		yield put({
			type: 'COMPANY_IDP_FLOW_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/idp-flow/${action.companyFlowId}/settings`, action.request);
		yield put({
			type: 'COMPANY_IDP_FLOW_UPDATE_SETTINGS_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyIdpFlowDelete(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/idp-flow/${action.companyFlowId}`);
		yield put({
			type: 'COMPANY_IDP_FLOW_DELETE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStyleSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/style');
		yield put({
			type: 'COMPANY_FETCH_STYLE_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateStyleSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/style', action.settings);
		yield companyFetchStyleSettings();

		yield put({
			type: 'SESSION_UPDATE_COMPANY_STYLE',
			companyStyle: action.settings
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchOidcInstanceList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/oidc-instance');
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_FETCH_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreateOidcInstance(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/company/current/settings/oidc-instance', action.settings);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_CREATE_SUCCESS',
			createdOidcInstanceId: id
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchOidcInstanceSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/settings`);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateOidcInstanceSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/settings`, action.settings);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateOidcInstanceLogo(action) {
	try {
		if (!!action.fileSize && action.fileSize > MAX_FILE_SIZE) {
			yield put({
				type: 'COMPANY_ERROR',
				oidcInstanceLogoUpdateError: 'IMAGE_INVALID_SIZE'
			});
			return;
		}

		yield axiosPost(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/logo`, {
			logoBase64: action.logoBase64,
			mimeType: action.mimeType
		});
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS',
			logoBase64: action.logoBase64,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			oidcInstanceLogoUpdateError: yield extractServerError(e)
		});
	}
}

function* companyDeleteOidcInstance(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}`);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_DELETE_SUCCESS'
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/company/current/sandbox-account/overview', action.request);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountCreate(action) {
	try {
		yield axiosPost('/api/internal/company/current/sandbox-account/', action.request);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_CREATE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountImport(action) {
	try {
		yield axiosPost('/api/internal/company/current/sandbox-account/import', action.request);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_CREATE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountFetch(action) {
	try {
		const {data} = yield axiosGet( `/api/internal/company/current/sandbox-account/${action.sandboxAccountId}/`, action.request);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_FETCH_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountUpdate(action) {
	try {
		yield axiosPut( `/api/internal/company/current/sandbox-account/${action.sandboxAccountId}/`, action.request);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_UPDATE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySandboxAccountDelete(action) {
	try {
		yield axiosDelete( `/api/internal/company/current/sandbox-account/${action.sandboxAccountId}/`);
		yield put({
			type: 'COMPANY_SANDBOX_ACCOUNT_DELETE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** FOLDER ***********************************************************************************************************/

function* folderFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/folder/overview', action.request);
		yield put({
			type: 'FOLDER_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderCreate(action) {
	try {
		const {data} = yield axiosPost('/api/internal/folder', action.request);
		yield put({
			type: 'FOLDER_CREATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/settings`);
		yield put({
			type: 'FOLDER_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/folder/${action.folderId}/settings`, action.settings);
		yield put({
			type: 'FOLDER_UPDATE_SETTINGS_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderDelete(action) {
	try {
		yield axiosDelete(`/api/internal/folder/${action.folderId}`);
		yield put({
			type: 'FOLDER_DELETE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchRelevantAccounts(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/relevant-accounts`);
		yield put({
			type: 'FOLDER_FETCH_RELEVANT_ACCOUNTS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchPermissions(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/permissions`);
		yield put({
			type: 'FOLDER_FETCH_PERMISSIONS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderUpdatePermissions(action) {
	try {
		yield axiosPut(`/api/internal/folder/${action.folderId}/permissions`, action.permissions);
		yield put({
			type: 'FOLDER_UPDATE_PERMISSIONS_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** PLATFORM *********************************************************************************************************/

function* platformFetchInfo(action) {
	try {
		const {data} = yield axiosGet('/api/internal/platform/info');
		yield put({
			type: 'PLATFORM_FETCH_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'PLATFORM_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** VISIT ************************************************************************************************************/

function* visitFetchOverviewInfo(action) {
	try {
		const {data} = yield axiosGet('/api/internal/internal-visit/overview-info');
		yield put({
			type: 'VISIT_FETCH_OVERVIEW_INFO_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/internal-visit/overview', action.request);
		yield put({
			type: 'VISIT_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitFetchInfo(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/internal-visit/${action.id}/info`);
		yield put({
			type: 'VISIT_FETCH_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitDelete(action) {
	try {
		yield axiosDelete(`/api/internal/internal-visit/${action.id}`);
		yield put({
			type: 'VISIT_DELETE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitRemoteIdSessionCreate(action) {
	try {
		const {data} = yield axiosPost('/api/internal/internal-visit/remote-id-session', action.request);
		yield put({
			type: 'VISIT_REMOTE_ID_SESSION_CREATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitRemoteIdSessionResendSms(action) {
	try {
		yield axiosPost(`/api/internal/internal-visit/${action.id}/remote-id-session/resend-sms`, action.request);
		yield put({
			type: 'VISIT_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('visit.remoteIdResendSmsSuccess')
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitDocumentIdFetchMedia(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/internal-visit/${action.id}/doc-id-session/media/${action.mediaId}`, 'blob');
		yield put({
			type: 'VISIT_DOCUMENT_ID_FETCH_MEDIA_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* visitDocumentIdManualReview(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/internal-visit/${action.id}/doc-id-session/manual-review`, action.request);
		yield put({
			type: 'VISIT_DOCUMENT_ID_MANUAL_REVIEW_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'VISIT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

