import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {IDENTIFICATION_ATTRIBUTE_TYPES} from "../common/Constants";
import {format, parse} from "date-fns";

const defaultState = {
	values: {},
	birthDate: null,
	eidCardExpiry: null,
	passportExpiry: null,
}

class CompanySandboxAccountUpdateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState, this.onFetch);
		} else if (!!this.props.companySandboxAccountEntry?.attributes && !prevProps.companySandboxAccountEntry?.attributes) {
			const attributes = this.props.companySandboxAccountEntry.attributes;
			this.setState({
				values: attributes,
				birthDate: !!attributes?.BIRTH_DATE ? parse(attributes.BIRTH_DATE, 'yyyy-LL-dd', new Date()) : null,
				eidCardExpiry: !!attributes?.EID_CARD_EXPIRY ? new Date(attributes.EID_CARD_EXPIRY) : null,
				passportExpiry: !!attributes?.PASSPORT_EXPIRY ? new Date(attributes.PASSPORT_EXPIRY) : null,
			});
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.sandboxAccountUpdate')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, ml: 1}}>
					{IDENTIFICATION_ATTRIBUTE_TYPES.map(type => <Box
						key={type}
						sx={{flexBasis: '45%', flexGrow: 1}}
					>
						{this.renderAttribute(type)}
					</Box>)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-update-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" onClick={this.onUpdate} disabled={this.props.companyBusy}  id="btn-update-confirm">
					{this.props.t('company.sandboxAccountUpdate')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	renderAttribute = (type) => {
		switch (type) {
			case 'GENDER':
			 	return this.renderOptions('GENDER', ['male', 'female', 'x'], 'company.sandboxAccountUpdateGender_');

			case 'BIRTH_DATE':
			case 'EID_CARD_EXPIRY':
			case 'PASSPORT_EXPIRY':
				return this.renderDate(type);

			default:
				return this.renderTextField(type);
		}
	}

	renderOptions = (type, options, optionKeyPrefix) => {
		return <FormControl
			size="small"
			fullWidth
		>
			<InputLabel id={type + '-label'}>{this.props.t('company.sandboxAccountUpdateAttribute_' + type)}</InputLabel>
			<Select
				labelId={type + '-label'}
				value={this.state.values[type] || ''}
				label={this.props.t('company.sandboxAccountUpdateAttribute_' + type)}
				onChange={(e) => this.onChangeAttribute(type, e.target.value)}
			>
				{options.map(option => <MenuItem key={option} value={option}>{this.props.t(optionKeyPrefix + option)}</MenuItem>)}
			</Select>
		</FormControl>
	}

	renderTextField = (type) => {
		const value = this.state.values[type] || '';

		return <TextField
			fullWidth
			size="small"
			value={value}
			label={this.props.t('company.sandboxAccountUpdateAttribute_' + type)}
			autoComplete="off"
			disabled={this.props.busy}
			onChange={e => this.onChangeAttribute(type, e.target.value)}
		/>;
	}

	renderDate = (type) => {
		const value = (() => {
			switch (type) {
				case 'BIRTH_DATE': return this.state.birthDate;
				case 'EID_CARD_EXPIRY': return this.state.eidCardExpiry;
				case 'PASSPORT_EXPIRY': return this.state.passportExpiry;
			}
		})();

		return <DatePicker
			slotProps={{
				textField: {
					fullWidth: true,
					variant: 'outlined',
					size: 'small',
					autoComplete: 'off',
					label: this.props.t('company.sandboxAccountUpdateAttribute_' + type),
				}
			}}
			views={['year', 'month', 'day']}
			openTo="year"
			value={value}
			onChange={(value) => this.onChangeAttribute(type, value)}
			format="yyyy-LL-dd"
			disableFuture={'BIRTH_DATE' === type}
		/>;
	}

	onChangeAttribute = (type, value) => {
		const newState = {}

		if ('BIRTH_DATE' === type) {
			newState.birthDate = value;
			value = (!!value && value instanceof Date && !isNaN(value)) ? format(value, 'yyyy-LL-dd') : undefined;
		} else if ('EID_CARD_EXPIRY' === type || 'PASSPORT_EXPIRY' === type) {
			if ('EID_CARD_EXPIRY' === type) {
				newState.eidCardExpiry = value;
			} else {
				newState.passportExpiry = value;
			}
			value = (!!value && value instanceof Date && !isNaN(value)) ? format(value, 'yyyy-LL-dd\'T\'HH:mmxx') : undefined;
		} else if ('PHONE_NUMBER' === type) {
			value = value.replace(/[^+0-9]+/g, '');
			if (value.length > 12) {
				value = value.substring(0, 12);
			}
		} else if (value?.length > 48) {
			value = value.substring(0, 48);
		}

		newState.values = { ...this.state.values };
		newState.values[type] = value;

		this.setState(newState);
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter') {
			this.onUpdate();
		}
	}

	onUpdate = () => {
		this.props.onUpdate({attributes: this.state.values});
	}

	onFetch = () => {
		this.props.onCompanySandboxAccountFetch(this.props.sandboxAccountId)
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyBusy: state.company.busy,
			companySandboxAccountEntry: state.company.sandboxAccountEntry
		}
	},
	dispatch => {
		return {
			onCompanySandboxAccountFetch: (sandboxAccountId) => {
				dispatch({
					type: 'COMPANY_SANDBOX_ACCOUNT_FETCH',
					sandboxAccountId
				});
			}
		}
	}
)(CompanySandboxAccountUpdateDialog));
