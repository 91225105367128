import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl, InputLabel, MenuItem, Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField
} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	description: '',
	companyFolderId: null,
}

class CompanyIdpFlowCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}

		if (!this.state.companyFolderId && this.props.folders?.length > 0) {
			// try to find the default folder, or the first one
			const companyFolderId = this.props.folders?.filter(folder => folder.defaultFolder)?.[0]?.id || this.props.folders[0]?.id;
			this.setState({companyFolderId});
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.idpFlowCreate')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.idpFlowSettingsGeneralHeader')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.idpFlowDescription')}
								required
								value={this.state.description}
								onChange={this.onChangeDescription}
								autoComplete="off"
								fullWidth
								autoFocus
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.idpFlowSettingsFolderHeader')}</StepLabel>
						<StepContent>
							<FormControl
								fullWidth
								required
							>
								<InputLabel
									id="idp-flow-create-company-folder"
								>
									{this.props.t('company.idpFlowCompanyFolderName')}
								</InputLabel>
								<Select
									labelId="idp-flow-create-company-folder"
									label={this.props.t('company.idpFlowCompanyFolderName')}
									value={this.state.companyFolderId || ''}
									onChange={this.onChangeCompanyFolderId}
								>
									{this.props.folders?.map(folder =>
										<MenuItem key={folder.id} value={folder.id}>{folder.name}</MenuItem>
									)}
								</Select>
							</FormControl>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!this.canContinue()} onClick={this.onCreate}
						id="btn-create-confirm">
					{this.props.t('company.idpFlowCreate')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeDescription = (e) => {
		this.setState({description: e.target.value});
	}

	onChangeCompanyFolderId = (e) => {
		this.setState({companyFolderId: e.target.value});
	}

	canContinue = () => {
		return !!this.state.description && this.state.description.length > 0 && !!this.state.companyFolderId;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		const {description, companyFolderId} = this.state;
		this.props.onCreate({description, companyFolderId});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanyIdpFlowCreateDialog));
