import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepContent, StepLabel, Stepper, TextField} from "@mui/material";
import {withTranslation} from "react-i18next";

const defaultState = {
	name: '',
}

class CompanyOidcInstanceCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('oidcInstance.create')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('oidcInstance.createName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('oidcInstance.name')}
								required
								value={this.state.name}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
								size="small"
							/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained"
						disabled={!this.state.name.trim()}
						onClick={this.onSave}
						id="btn-create-confirm"
				>
					{this.props.t('oidcInstance.create')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && !!this.state.name.trim()) {
			this.onSave();
		}
	}

	onSave = () => {
		this.props.onSave({
			name: this.state.name,
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(CompanyOidcInstanceCreateDialog));
