import React, {Component, useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {add, differenceInDays, differenceInMonths, format, isValid, startOfDay, startOfYear, sub,} from "date-fns";
import {Bar} from "react-chartjs-2";
import {
	Alert,
	Box,
	Button, Card,
	Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	Typography
} from "@mui/material";
import {deepOrange, indigo, lightBlue, orange, purple, teal, yellow} from "@mui/material/colors";
import {useTranslation, withTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {MAP_DATE_FNS_LOCALE} from "../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";

const STATS_CHART_DEFAULT_OPTIONS = {
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true
		}
	},
	maintainAspectRatio: false,
	animations: false,							// animations disabled to prevent lag when hiding multiple datasets in a single clickevent
	plugins: {
		legend: {
			display: true,
			title: {
				display: true,
				font: {
					weight: 'bold'
				}
			},
			onHover: function (event, legendItem) {
				event.native.target.style.cursor = legendItem ? 'pointer' : 'default';
			},
			onLeave: function (e) {
				e.native.target.style.cursor = 'default';
			},
			onClick: function (e, legendItem, legend) {
				// override to handle hiding all datasets corresponding to the selected legend 'type' (signatureType, approved/rejected)
				const chart = legend.chart;
				const datasets = chart.data.datasets;
				const targetStack = legendItem.stack;
				let hide = chart.isDatasetVisible(legendItem.datasetIndex);
				legendItem.hidden = hide;

				// hide all other stacks of the same type
				for (let i = 0; i < datasets.length; i++) {
					if (datasets[i].stack === targetStack) {
						if (hide) {
							chart.hide(i)
						} else {
							chart.show(i);
						}
					}
				}
			},
			labels: {
				// override to only show legend items when firstOfStack is true (so we only get 1 legend item)
				generateLabels: chart => chart.data.datasets.map((ds, i) => ({
					firstOfStack: chart.getDatasetMeta(i)._dataset?.firstOfStack,
					stack: chart.getDatasetMeta(i).stack,
					text: !!chart.getDatasetMeta(i)._dataset?.legendLabel ? chart.getDatasetMeta(i)._dataset?.legendLabel : ds.label,
					datasetIndex: i,
					fillStyle: chart.data.datasets[i].backgroundColor,
					strokeStyle: chart.data.datasets[i].backgroundColor,
					hidden: chart.getDatasetMeta(i).hidden
				})).filter(item => {
					return item.firstOfStack;
				})
			}
		}
	}
};

const STATS_BACKGROUND_COLOR_SHADES = ['500', '600', '700', '200', '300', '400'];
const STATS_BACKGROUND_COLOR_HUES = [orange, lightBlue, teal, purple, yellow, indigo, deepOrange];

const STATS_AGGREGATION_TYPES = [
	{key: 'NONE', translateText: (props) => props.t('company.statsAggregationType_NONE')},
	{key: 'UNIQUE_USERS', translateText: (props) => props.t('company.statsAggregationType_UNIQUE_USERS')},
	{key: 'FIRST_TIME_UNIQUE_USERS', translateText: (props) => props.t('company.statsAggregationType_FIRST_TIME_UNIQUE_USERS')}
];

const STATS_SOURCES = [
	{key: 'EID_AUTHENTICATION', translateText: (props) => props.t('company.statsSource_EID_AUTHENTICATION')},
	{key: 'EID_IDENTIFICATION', translateText: (props) => props.t('company.statsSource_EID_IDENTIFICATION')},
	{key: 'ITSME', translateText: (props) => props.t('company.statsSource_ITSME')},
	{key: 'DOC_ID', translateText: (props) => props.t('company.statsSource_DOC_ID')}
];

const FOLDER_FILTER_NONE = -1;

const CompanyStatsDetailsDialog = ({open, onClose, onExport}) => {
	const {t} = useTranslation();

	const [beginDate, onChangeBeginDate] = useState(null);
	const [endDate, onChangeEndDate] = useState(null);
	const [beginDateError, onChangeBeginDateError] = useState(false);
	const [endDateError, onChangeEndDateError] = useState(false);

	const onLocalClose = useCallback((e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}, []);

	useEffect(() => {
		if (open) {
			const endDate = startOfDay(new Date());
			onChangeEndDate(endDate);
			onChangeEndDateError(false);
			const beginDate = sub(endDate, {months: 1});
			onChangeBeginDate(beginDate);
			onChangeBeginDateError(false);
		}
	}, [open]);

	const onLocalBeginDateChange = useCallback((value) => {
		if (null === value || !isValid(value)) {
			onChangeBeginDateError(true);
			return;
		}
		const delta = differenceInDays(endDate, value);
		if (delta < 2 || delta > 365) {
			onChangeBeginDateError(true);
			return;
		}
		onChangeBeginDate(value);
		onChangeBeginDateError(false);
	}, [endDate]);

	const onLocalEndDateChange = useCallback((value) => {
		if (null === value || !isValid(value)) {
			onChangeEndDateError(true);
			return;
		}
		const delta = differenceInDays(value, beginDate);
		if (delta < 2 || delta > 365) {
			onChangeEndDateError(true);
			return;
		}
		onChangeEndDate(value);
		onChangeEndDateError(false);
	}, [beginDate]);

	const onLocalExport = useCallback(() => onExport({beginDate, endDate}), [beginDate, endDate]);

	return <Dialog open={open}
				   onClose={onLocalClose}
				   fullWidth
				   maxWidth="sm"
	>
		<DialogTitle>{t('company.statsDetails')}</DialogTitle>
		<DialogContent>
			<Box sx={{pt: 1, display: 'flex', flexDirection: 'column', gap: 1}}>
				<DatePicker
					slotProps={{
						textField: {
							size: 'small',
							autoComplete: 'off',
							fullWidth: true,
							error: beginDateError,
							inputProps: {
								placeholder: ''
							}
						}
					}}
					label={t('company.statsBeginDate')}
					value={beginDate}
					onChange={onLocalBeginDateChange}
					views={['year', 'month', 'day']}
					openTo={'day'}
					format={'dd/LL/yyyy'}
				/>
				<DatePicker
					slotProps={{
						textField: {
							size: 'small',
							autoComplete: 'off',
							fullWidth: true,
							error: endDateError,
							inputProps: {
								placeholder: ''
							}
						}
					}}
					label={t('company.statsEndDate')}
					value={endDate}
					onChange={onLocalEndDateChange}
					views={['year', 'month', 'day']}
					openTo={'day'}
					format={'dd/LL/yyyy'}
				/>
				{(beginDateError || endDateError) && <Alert severity="error">
					{t('company.statsDateError')}
				</Alert>}
			</Box>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={onLocalClose}
				id="btn-cancel"
			>
				{t('cancel')}
			</Button>
			<Button
				variant="contained"
				onClick={onLocalExport}
				id="btn-export"
				startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
			>
				{t('export')}
			</Button>
		</DialogActions>
	</Dialog>;
}

class CompanyStatisticsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			statsSource: 'ITSME',
			statsAggregationType: 'FIRST_TIME_UNIQUE_USERS',
			statsBeginDate: startOfYear(new Date()),
			statsBeginDateError: false,
			statsFolderFilter: [FOLDER_FILTER_NONE],

			detailsDialogOpen: false,
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchStatsConfiguration();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.companyStatsConfig !== prevProps?.companyStatsConfig) {
			this.onFetchStats();
		}
	}

	render() {
		const {statsSource, statsAggregationType, statsBeginDate, statsBeginDateError, statsFolderFilter} = this.state;
		const {sessionInfo, companyStats} = this.props;

		const chartOptions = {
			...STATS_CHART_DEFAULT_OPTIONS,
			plugins: {
				...STATS_CHART_DEFAULT_OPTIONS.plugins,
				legend: {
					display: false
				},
			}
		};

		const count = 12;
		const labelFormat = 'MMMM';
		const views = ['year'];
		const openTo = 'year';
		const inputFormat = 'yyyy';

		const labels = [];
		const offsets = [];
		for (let i = 0; i < count; i++) {
			const entryDate = add(statsBeginDate, {months: i});
			labels.push(format(entryDate, labelFormat, {locale: MAP_DATE_FNS_LOCALE(sessionInfo)}));
			offsets.push(format(entryDate, 'yyyy-LL-dd\'T\'HH:mm:ss'));
		}

		const datasets = count > 0 ? this.buildDatasets(companyStats, count, offsets) : [];
		const folderFilterSelectionList = [
			{id: FOLDER_FILTER_NONE, name: this.props.t('company.statsFolderFilter_NONE')},
			...(this.props.companyStatsConfig?.folderList || [])
		];

		return <>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
				<Box sx={{height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					{datasets.length > 0 && <Bar options={chartOptions} data={{labels, datasets}}/>}
					{datasets.length === 0 &&
						<Typography variant="body2">{this.props.t('company.statsNoData')}</Typography>}
				</Box>
				<Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1, flexWrap: 'wrap'}}>
					<Button
						variant="contained"
						onClick={this.onOpenDetailsDialog}
						id="btn-company-statistics-details"
					>
						{this.props.t('company.statsDetails')}
					</Button>
					<Box sx={{flexGrow: 1}} />
					<Button
						variant="contained"
						startIcon={<FontAwesomeIcon icon={faFileCsv}/>}
						onClick={this.onExportStats}
						id="btn-company-statistics-export"
					>
						{this.props.t('export')}
					</Button>
					<Select
						size="small"
						value={statsSource}
						onChange={this.onChangeStatsSource}
					>
						{STATS_SOURCES.map(source =>
							<MenuItem key={source.key} value={source.key}>{source.translateText(this.props)}</MenuItem>)}
					</Select>
					<Select
						size="small"
						value={statsAggregationType}
						onChange={this.onChangeStatsAggregationType}
					>
						{STATS_AGGREGATION_TYPES.map(type =>
							<MenuItem key={type.key} value={type.key}>{type.translateText(this.props)}</MenuItem>)}
					</Select>
					<FormControl size="small">
						<InputLabel id="folder-selection-label">{this.props.t('company.statsFolder')}</InputLabel>
						<Select
							labelId="folder-selection-label"
							label={this.props.t('company.statsFolder')}
							multiple
							value={statsFolderFilter}
							onChange={this.onChangeFolderFilter}
							input={<OutlinedInput label="Folder" />}
							renderValue={(folderIds) => folderFilterSelectionList?.filter((folder) => folderIds.includes(folder.id)).map(folder => folder.name).join(', ')}
							sx={{width: '200px'}}
						>
							{folderFilterSelectionList?.map((folder) => (
								<MenuItem key={folder.id} value={folder.id} divider={folder.id === FOLDER_FILTER_NONE}>
									<Checkbox size="small" checked={!!statsFolderFilter && statsFolderFilter.indexOf(folder.id) > -1} />
									<ListItemText primary={folder.name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<DatePicker
						slotProps={{
							textField: {
								size: 'small',
								autoComplete: 'off',
								error: statsBeginDateError,
								inputProps: {
									placeholder: ''
								}
							}
						}}
						label={this.props.t('company.statsYear')}
						value={statsBeginDate}
						onChange={this.onChangeStatsBeginDate}
						views={views}
						openTo={openTo}
						format={inputFormat}
					/>
				</Box>
			</Box>
			<CompanyStatsDetailsDialog
				open={this.state.detailsDialogOpen}
				onClose={this.onCloseDetailsDialog}
				onExport={this.onExportStatsDetails}
			/>
		</>;
	}

	buildDatasets(stats, timePartitionCount, offsets) {
		const dataset = {
			stack: 'count',
			firstOfStack: true,
			data: new Array(timePartitionCount).fill(0),
			label: this.props.t('company.statsCount'),
			backgroundColor: this.getBackgroundColor(0)
		}

		stats?.forEach(stat => {
			const offset = offsets.indexOf(stat.dateTime);
			if (offset >= 0) {
				dataset.data[offset] = stat.count;
			}
		});

		return [dataset];
	}

	getBackgroundColor = (stackIndex) => {
		const hueColor = STATS_BACKGROUND_COLOR_HUES[stackIndex % STATS_BACKGROUND_COLOR_HUES.length];
		return hueColor[STATS_BACKGROUND_COLOR_SHADES[stackIndex % STATS_BACKGROUND_COLOR_SHADES.length]];
	}

	onOpenDetailsDialog = () => {
		this.setState({
			detailsDialogOpen: true
		});
	}

	onCloseDetailsDialog = () => {
		this.setState({
			detailsDialogOpen: false
		});
	}

	onChangeStatsSource = (e) => {
		const statsSource = e.target.value;
		this.setState({statsSource}, this.onFetchStats);
	}

	onChangeStatsAggregationType = (e) => {
		const statsAggregationType = e.target.value;
		this.setState({statsAggregationType}, this.onFetchStats);
	}

	onChangeFolderFilter = (e) => {
		let filter = e.target.value;
		const previousFolderFilter = this.state.statsFolderFilter;
		const newValue = filter.filter(x => !previousFolderFilter.includes(x))[0];

		if (!newValue && previousFolderFilter.length === 1) {
			return;
		}

		// clear other values if we don't want to filter
		if (FOLDER_FILTER_NONE === newValue) {
			filter = [FOLDER_FILTER_NONE];
		} else if (previousFolderFilter.includes(FOLDER_FILTER_NONE)) {
			filter = [newValue];
		}

		this.setState({statsFolderFilter: filter}, this.onFetchStats)
	}

	onChangeStatsBeginDate = (value) => {
		if (null === value || !isValid(value)) {
			this.setState({statsBeginDateError: true});
			return;
		}

		this.setState({statsBeginDate: value}, this.onFetchStats);
	}

	onFetchStats = () => {
		const noneFolderFilter = this.state.statsFolderFilter.includes(FOLDER_FILTER_NONE);
		const folderIds = noneFolderFilter ? null : this.state.statsFolderFilter;

		this.props.onCompanyFetchStats({
			...(this.createBaseRequest()),
			folderPartition: false,
			folderIds,
			methods: [this.state.statsSource]
		});
	}

	onExportStats = () => {
		this.props.onCompanyExportStats({
			...(this.createBaseRequest()),
			folderPartition: true
		})
	}

	createBaseRequest = () => {
		const {statsAggregationType, statsBeginDate} = this.state;
		const from = format(statsBeginDate, 'yyyy-LL-dd\'T\'HH:mm:ss');
		const to = format(add(statsBeginDate, {years: 1}), 'yyyy-LL-dd\'T\'HH:mm:ss');
		return {
			from,
			to,
			aggregationType: statsAggregationType,
			timePartition: 'MONTH',
		};
	}

	onExportStatsDetails = ({beginDate, endDate}) => {
		const from = format(beginDate, 'yyyy-LL-dd\'T\'HH:mm:ss');
		const to = format(endDate, 'yyyy-LL-dd\'T\'HH:mm:ss');
		this.setState({
			detailsDialogOpen: false
		}, () => this.props.onCompanyExportStatsDetails({from, to}));
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyStatsConfig: state.company.statsConfig,
			companyStats: state.company.stats,
		}
	},
	dispatch => {
		return {
			onCompanyFetchStatsConfiguration: () => {
				dispatch({
					type: 'COMPANY_FETCH_STATS_CONFIG',
				})
			},
			onCompanyFetchStats: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_STATS',
					request
				})
			},
			onCompanyExportStats: (request) => {
				dispatch({
					type: 'COMPANY_EXPORT_STATS',
					request
				})
			},
			onCompanyExportStatsDetails: (request) => {
				dispatch({
					type: 'COMPANY_EXPORT_STATS_DETAILS',
					request
				})
			}
		}
	}
)(CompanyStatisticsComponent));
