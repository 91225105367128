import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingComponent from "../common/LoadingComponent";
import CompanyIdpFlowCustomElementsEditorDialog from "./CompanyIdpFlowCustomElementsEditorDialog";

const IDENTIFICATION_METHODS = ['EID', 'ITSME', 'MYID', 'DOC_ID', 'MANUAL_INPUT'];
const DOC_ID_ASSURANCE_LEVELS = ['LIVENESS', 'FACE_COMPARE', 'NONE'];
const DOC_ID_VALIDATION_MODES = ['ONLINE_STRICT', 'ONLINE', 'BALANCED', 'OFFLINE'];

class CompanyIdpFlowSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customElementsDialogOpen: false
		}
	}

	render() {
		const settings = this.props.settings;
		return <>
			<Dialog
				open={this.props.open}
				onClose={this.onClose}
				onKeyUp={this.onKeyUp}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>{this.props.t('company.idpFlowSettings')}</DialogTitle>
				{!settings && <DialogContent><LoadingComponent/></DialogContent>}
				{!!settings &&
					<DialogContent>
						<Stepper activeStep={-1} orientation="vertical">
							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsGeneralHeader')}</StepLabel>
								<StepContent>
									<TextField
										variant="outlined"
										label={this.props.t('company.idpFlowDescription')}
										required
										value={settings?.description || ''}
										onChange={this.onChangeDescription}
										autoComplete="off"
										fullWidth
										autoFocus
									/>

								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsFolderHeader')}</StepLabel>
								<StepContent>
									<FormControl
										fullWidth
										required
									>
										<InputLabel
											id="idp-flow-settings-company-folder"
										>
											{this.props.t('company.idpFlowCompanyFolderName')}
										</InputLabel>
										<Select
											labelId="idp-flow-settings-company-folder"
											label={this.props.t('company.idpFlowCompanyFolderName')}
											value={settings.companyFolderId || ''}
											onChange={this.onChangeCompanyFolderId}
										>
											{this.props.folders?.map(folder =>
												<MenuItem key={folder.id} value={folder.id}>{folder.name}</MenuItem>
											)}
										</Select>
									</FormControl>
								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsAccessManagementHeader')}</StepLabel>
								<StepContent>
									<TextField
										variant="outlined"
										label={this.props.t('company.idpFlowAllowUrlList')}
										value={settings?.allowListUrls || ''}
										onChange={this.onChangeAllowListUrls}
										autoComplete="off"
										fullWidth
										sx={{mt: 1}}
									/>
								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowIdentificationMethodsHeader')}</StepLabel>
								<StepContent>
									<Typography>{this.props.t('company.idpFlowIdentificationMethodsAllowedMethods')}</Typography>
									<FormGroup sx={{ml: 1, mb: 1}}>
										{IDENTIFICATION_METHODS.map(identificationMethod => <Box key={identificationMethod}
																								 sx={{
																									 display: 'flex',
																									 alignItems: 'center'
																								 }}>
											<FormControlLabel
												control={
													<Checkbox
														checked={settings?.identificationMethods.indexOf(identificationMethod) >= 0}
														onChange={(e, enabled) => this.onChangeIdentificationMethod(identificationMethod, enabled)}
														name={identificationMethod}/>
												}
												label={this.props.t('company.idpFlowIdentificationMethod_' + identificationMethod)}
												sx={{p: 0}}
											/>
											{'ITSME' === identificationMethod && <FormControl
												fullWidth
												size="small"
												disabled={settings?.identificationMethods.indexOf(identificationMethod) === -1}
											>
												<InputLabel
													id="idp-flow-settings-itsme-config"
												>
													{this.props.t('company.idpFlowItsmeConfig')}
												</InputLabel>
												<Select
													labelId="idp-flow-settings-itsme-config"
													label={this.props.t('company.idpFlowItsmeConfig')}
													value={settings.companyItsmeConfigId || ''}
													onChange={this.onChangeItsmeConfigId}
												>
													{this.props.itsmeConfigs?.map(config =>
														<MenuItem key={config.id} value={config.id}>{config.name}</MenuItem>
													)}
												</Select>
											</FormControl>}
										</Box>)}
									</FormGroup>

									<Typography>{this.props.t('company.idpFlowIdentificationMethodsEnforcedAttributes')}</Typography>
									<Box sx={{ml: 1}}>
										<FormControlLabel
											control={
												<Checkbox checked={settings?.identityAttributesMandatory}
														  onChange={(e, enabled) => this.onChangeIdentityAttributesMandatory(enabled)}
												/>
											}
											label={this.props.t('company.idpFlowIdentityAttributesMandatory')}
											sx={{p: 0}}
										/>
									</Box>
								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsDocId')}</StepLabel>
								<StepContent>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
										<Typography
											sx={{flexBasis: 150}}>{this.props.t('company.idpFlowSettingsDocIdAssuranceLevel')}</Typography>
										<Select
											size="small"
											value={settings.docIdAssuranceLevel || ''}
											onChange={this.onChangeDocIdAssuranceLevel}
											sx={{flexGrow: 1}}
										>
											{DOC_ID_ASSURANCE_LEVELS.map(level =>
												<MenuItem
													key={level}
													value={level}
												>
													{this.props.t('company.idpFlowSettingsDocIdAssuranceLevel_' + level)}
												</MenuItem>
											)}
										</Select>
									</Box>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 1, mt: 1}}>
										<Typography
											sx={{flexBasis: 150}}>{this.props.t('company.idpFlowSettingsDocIdValidationMode')}</Typography>
										<Select
											size="small"
											value={settings.docIdValidationMode || ''}
											onChange={this.onChangeDocIdValidationMode}
											sx={{flexGrow: 1}}
										>
											{DOC_ID_VALIDATION_MODES.map(mode =>
												<MenuItem key={mode}
														  value={mode}>{this.props.t('company.idpFlowSettingsDocIdValidationMode_' + mode)}</MenuItem>
											)}
										</Select>
									</Box>
								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsCustomElements')}</StepLabel>
								<StepContent>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
										<Button
											variant="contained"
											color="secondary"
											onClick={this.onOpenCustomElementsDialog}
											id="btn-custom-elements"
										>
											{this.props.t('company.idpFlowSettingsCustomElementsEdit')}
										</Button>
									</Box>
								</StepContent>
							</Step>

							<Step active>
								<StepLabel>{this.props.t('company.idpFlowSettingsClientId')}</StepLabel>
								<StepContent>
									<TextField
										variant="outlined"
										label={this.props.t('company.idpFlowClientId')}
										disabled
										value={settings?.clientId || ''}
										fullWidth
										sx={{mt: 1}}
										InputProps={{
											endAdornment: <IconButton color="primary"
																	  title={this.props.t('company.idpFlowClientIdCopy')}
																	  onClick={this.onCopyClientId}
																	  size="small"
											>
												<ContentCopyIcon/>
											</IconButton>
										}}
									/>
								</StepContent>
							</Step>

						</Stepper>
					</DialogContent>}
				<DialogActions>
					<Button onClick={this.onClose} id="btn-update-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained" disabled={!this.canContinue()} onClick={this.onSave}
							id="btn-update-confirm">
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>

			<CompanyIdpFlowCustomElementsEditorDialog
				open={this.state.customElementsDialogOpen}
				onClose={this.onCloseCustomElementsDialog}
				onSave={this.onSaveCustomElements}
				customElementTypes={this.props.customElementTypes}
				customElements={settings?.customElements}
			/>
		</>
	}

	onChangeDescription = (e) => {
		this.props.onChangeFlowSetting('description', e.target.value);
	}

	onChangeCompanyFolderId = (e) => {
		this.props.onChangeFlowSetting('companyFolderId', e.target.value);
	}

	onChangeAllowListUrls = (e) => {
		this.props.onChangeFlowSetting('allowListUrls', e.target.value);
	}

	onChangeIdentificationMethod = (changedMethod, enabled) => {
		const identificationMethods = this.props.settings.identificationMethods
			.filter(identificationMethod => identificationMethod !== changedMethod);
		if (enabled) {
			identificationMethods.push(changedMethod);
		}

		this.props.onChangeFlowSetting('identificationMethods', identificationMethods);
	}

	onChangeItsmeConfigId = (e) => {
		this.props.onChangeFlowSetting('companyItsmeConfigId', e.target.value);
	}

	onChangeIdentityAttributesMandatory = (enabled) => {
		this.props.onChangeFlowSetting('identityAttributesMandatory', enabled);
	}

	onChangeDocIdAssuranceLevel = (e) => {
		this.props.onChangeFlowSetting('docIdAssuranceLevel', e.target.value);
	}

	onChangeDocIdValidationMode = (e) => {
		this.props.onChangeFlowSetting('docIdValidationMode', e.target.value);
	}

	onOpenCustomElementsDialog = () => {
		this.setState({customElementsDialogOpen: true});
	}

	onCloseCustomElementsDialog = () => {
		this.setState({customElementsDialogOpen: false});
	}

	onSaveCustomElements = (customElements) => {
		this.setState({customElementsDialogOpen: false}, () => this.props.onChangeFlowSetting('customElements', customElements));
	}

	onCopyClientId = () => {
		navigator?.clipboard?.writeText(this.props.settings.clientId);
	}

	canContinue = () => {
		return !!this.props.settings?.description && this.props.settings.description.length > 0;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onSave();
		}
	}

	onSave = () => {
		this.props.onSave(this.props.settings);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {
			settings: state.company.idpFlowSettings
		}
	},
	dispatch => {
		return {
			onChangeFlowSetting: (key, value) => {
				dispatch({
					type: 'COMPANY_IDP_FLOW_CHANGE_SETTING',
					key,
					value
				});
			}
		}
	}
)(CompanyIdpFlowSettingsDialog));
